import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { HttpService } from '../http/http.service';

@Injectable()
export class TelegramService {
    constructor(private http: HttpService) {}

    public getAliveAccounts(): Observable<any> {
        return this.http.get(`/api/telegram/alive`);
    }
}
