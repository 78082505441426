import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { PermissionsService } from '../permissions/permissions.service';
import { UserService } from '../user/user.service';

@Injectable()
export class UserCheckAndSchoolAccessGuardService {
    constructor(
        private router: Router,
        private userService: UserService,
        private permissionsService: PermissionsService,
    ) {}

    public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        try {
            const user = await this.userService
                .checkUser(route.params.user_id)
                .toPromise();

            if (!user.success) {
                throw new Error('User Not Found');
            }

            if (
                !this.permissionsService.checkPermission({
                    name: 'users_profile_view',
                    schools: [user.response.school._id],
                })
            ) {
                this.router.navigate(['/error/403']);
                return false;
            }

            return user.success;
        } catch (error) {
            this.router.navigate(['/error/404']);
            return false;
        }
    }
}
