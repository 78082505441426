import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@services/user/user.service';

@Component({
    selector: 'app-layout-dialog',
    templateUrl: './layout-dialog.component.html',
    styleUrls: ['./layout-dialog.component.scss'],
})
export class LayoutDialogComponent {
    public items: any[] = [];

    constructor(
        private matDialogRef: MatDialogRef<LayoutDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private userService: UserService,
    ) {
        this.items = new Array(...this.data);
    }

    public submit(data: any): void {
        this.userService.updateUserNavigation(data).subscribe(({ success }) => {
            if (success) {
                this.matDialogRef.close(data);
            }
        });
    }

    public sortItems({ previousIndex, currentIndex }: CdkDragDrop<any>): void {
        const move = this.items[previousIndex];

        this.items.splice(previousIndex, 1);

        this.items.splice(currentIndex, 0, move);
    }

    public deleteItem(index: number): void {
        this.items.splice(index, 1);
    }
}
