export enum LANG {
    UK = 'uk',
    EN = 'en',
    DEFAULT = UK,
}

export const ALL_LANGUAGES = [LANG.UK, LANG.EN];

export const LANGUAGES = {
    en: { label: 'language_en', value: 'en' },
    uk: { label: 'language_uk', value: 'uk' },
};

export const TEACHING_LANGUAGES = {
    ...LANGUAGES,
    ru: { label: 'language_ru', value: 'ru' },
};
