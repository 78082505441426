import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { GlobalService } from '../global/global.service';
import { PermissionsService } from '../permissions/permissions.service';

@Injectable()
export class SprintsGuardService {
    public get schools(): any[] {
        return this.globalService.user.schools;
    }

    constructor(
        private router: Router,
        private permissionsService: PermissionsService,
        private globalService: GlobalService,
    ) {}

    public canActivate(): boolean | UrlTree {
        return (
            this.permissionsService.checkPermission({
                name: 'sprints',
            }) || this.router.parseUrl('/error/403')
        );
    }
}
