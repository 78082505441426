import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { PermissionsService } from '../permissions/permissions.service';

@Injectable()
export class EditAccountsGuardService {
    constructor(
        private router: Router,
        private permissionsService: PermissionsService,
    ) {}

    public canActivate(): boolean {
        if (
            !this.permissionsService.checkPermission({
                name: 'edit_account',
            })
        ) {
            this.router.navigate(['/error/403']);
            return false;
        }

        return true;
    }
}
