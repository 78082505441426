import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ProfilePhotoComponent } from './profile-photo.component';
import { I18NextModule } from 'angular-i18next';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';

@NgModule({
    declarations: [ProfilePhotoComponent],
    imports: [CommonModule, GalleryModule, LightboxModule, I18NextModule],
    exports: [ProfilePhotoComponent],
})
export class ProfilePhotoModule {}
