export enum SocketType {
    CHAT = 'chat',
    CHATS = 'chats',
    INVITES = 'invites',
    CAMERA = 'camera',
    VIDEOS = 'videos',
    PURCHASES = 'purchases',
}

export enum SocketState {
    CONNECT = 'connect',
    DISCONNECT = 'disconnect',
    RECONNECT = 'reconnect',
}

export enum ChatsRoom {
    JOIN_TO_SUPPORT = 'joinToSupport',
    LEAVE_FROM_SUPPORT = 'leaveFromSupport',
    UPDATE_CHATS_STAT = 'updateChatsStat',
    UPDATE_CHATS_LIST = 'updateChatOfList',
}

export enum ChatRoom {
    JOIN_TO_CHAT = 'joinToSupportChat',
    LEAVE_FROM_CHAT = 'leaveFromSupportChat',
    START_CHAT = 'startChat',
    STOP_CHAT = 'stopChat',
    UPDATE_STATUS = 'updateStatus',
    ADD_MESSAGE = 'addMessage',
    UPDATE_MESSAGE = 'update_message',
    REMOVE_MESSAGE = 'remove_message',
    ERROR_MESSAGE = 'error_message',
}

export enum InviteRoom {
    JOIN_TO_INVITES = 'joinToInvites',
    LEAVE_FROM_INVITES = 'leaveFromInvites',
    USER_ADDED = 'invite:user-added',
    CREATED = 'invite:created',
    UPDATED = 'invite:updated',
    REMOVED = 'invite:removed',
}

export enum VideosRoom {
    USER_CONNECTED = 'userConnected',
    USER_DISCONNECTED = 'userDisconnected',
    SCHOOL_RECORDER_CONNECTED = 'schoolRecorderConnected',
}

export enum CameraRoom {
    START_CAMERA = 'startCamera',
    STOP_CAMERA = 'stopCamera',
    CANCEL_AUTO_EVENT = 'cancelAutoEvent',

    UPDATE_CAMERA = 'updateCamera',
    UPDATE_IMAGE_ON_CAMERA = 'updateImageOnCamera',
    AUTO_UPDATE_CAMERA = 'autoUpdateCamera',
}

export enum PurchasesRoom {
    JOIN_PURCHASE_PAGE = 'joinPurchasePage',
    LEAVE_PURCHASE_PAGE = 'leavePurchasePage',
    ONLINE_PAYMENT_UPDATED = 'onlinePaymentUpdated',
}
