import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { GlobalService } from '../global/global.service';
import { PermissionsService } from '../permissions/permissions.service';

@Injectable()
export class GroupsRedirectGuardService {
    public get school(): any {
        return this.globalService.school;
    }

    public get groups_query(): any {
        return this.globalService.groups_query;
    }

    constructor(
        private router: Router,
        private permissionsService: PermissionsService,
        private globalService: GlobalService,
    ) {}

    public canActivate(): boolean {
        if (
            this.permissionsService.checkPermission({
                name: 'groups_not_ready',
                schools: [this.school._id],
            })
        ) {
            this.router.navigate(['/groups/recruit'], {
                queryParams: this.groups_query,
            });
        } else {
            this.router.navigate(['/groups/active'], {
                queryParams: this.groups_query,
            });
        }

        return true;
    }
}
