import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable()
export class ClickService {
    constructor(private http: HttpClient) {}

    public copy(data: string, set_to_buffer = true): void {
        if (!data) return;

        if (set_to_buffer) {
            (<any>navigator).clipboard
                .writeText(data)
                .then(() => {})
                .catch(() => {});
        }

        this.http
            .post(
                `${environment.apiUrl}/api/clicks`,
                {
                    data,
                    path: window.location.pathname,
                },
                {
                    headers: new HttpHeaders(),
                    withCredentials: true,
                },
            )
            .subscribe();
    }
}
