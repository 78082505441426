import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { GlobalService } from '../global/global.service';
import { SchoolsService } from '../schools/schools.service';

@Injectable()
export class SchoolCheckAndAccessGuardService {
    set school(value) {
        this.globalService.school = value;
    }

    constructor(
        private router: Router,
        private schoolsService: SchoolsService,
        private globalService: GlobalService,
    ) {}

    public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        try {
            const school = await this.schoolsService
                .getSchool(route.params.school_id)
                .toPromise();

            if (!school.success) {
                throw new Error('School Not Found');
            }

            if (
                !this.globalService.user.schools
                    .map((s) => s._id)
                    .includes(route.params.school_id)
            ) {
                this.router.navigate(['/error/403']);
                return false;
            }

            this.school = school.response;

            return school.success;
        } catch (error) {
            this.router.navigate(['/error/404']);
            return false;
        }
    }
}
