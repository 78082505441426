import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpParams } from '@angular/common/http';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {
    NavigationEnd,
    NavigationExtras,
    NavigationStart,
    Router,
} from '@angular/router';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { Constants } from '@constants/constants';
import { PosthogService } from '@services/posthog/posthog.service';

@Injectable()
export class GlobalService {
    public calendar_locale = {
        firstDayOfWeek: 1,
        dayNames: [
            'day_sunday',
            'day_monday',
            'day_tuesday',
            'day_wednesday',
            'day_thursday',
            'day_friday',
            'day_saturday',
        ],
        dayNamesShort: [
            'sunday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
        ],
    };

    public ranges_list = [
        {
            title: 'general:current_week',
            range: [moment().startOf('week'), moment().endOf('week')],
        },
        {
            title: 'general:current_month',
            range: [moment().startOf('month'), moment().startOf('day')],
        },
        {
            title: 'general:last_month',
            range: [
                moment().subtract(1, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month'),
            ],
        },
        {
            title: 'general:current_quarter',
            range: [moment().startOf('quarter'), moment().startOf('day')],
        },
        {
            title: 'general:last_quarter',
            range: [
                moment().subtract(1, 'quarter').startOf('quarter'),
                moment().subtract(1, 'quarter').endOf('quarter'),
            ],
        },
        {
            title: 'general:this_year',
            range: [moment().startOf('year'), moment().startOf('day')],
        },
        {
            title: 'general:last_year',
            range: [
                moment().subtract(1, 'year').startOf('year'),
                moment().subtract(1, 'year').endOf('year'),
            ],
        },
        {
            title: 'general:all_time',
            range: [moment('01.01.2017', 'DD.MM.YYYY'), moment()],
        },
    ];

    public ranges = this.ranges_list.reduce((acc, item) => {
        acc[item.title] = item.range;
        return acc;
    }, {});

    public payment_types = [
        {
            title: 'payment_type_full_payment_only',
            slug: 'full',
        },
        {
            title: 'payment_type_two_installments',
            slug: 'partial',
        },
        {
            title: 'payment_type_monthly',
            slug: 'monthly',
        },
        {
            title: 'payment_type_other',
            slug: 'other',
        },
    ];

    // Новые поля

    public user: any;
    public group: any = {};
    public permissions: any = {};
    public requests = [];
    public birthdayUsers = [];
    public cvs_search = new Subject();
    public cvs_last_search = '';
    public url_support_chat = false;
    public is_small_screen = false;
    public url_support = false;
    public return_after_login;

    private _groups_search = '';
    private _groups_query: any;

    public get groups_query(): any {
        return this._groups_query;
    }
    public set groups_query(value: any) {
        if (value) this._groups_query = value;
    }

    public set groups_search(value: string) {
        if (value) this._groups_search = value;
    }
    public get groups_search(): string {
        return this._groups_search;
    }

    get load(): boolean {
        return this.requests.length > 0;
    }

    public _school: any;

    set school(value) {
        this._school = value;
    }

    get school(): any {
        return this._school;
    }

    public _page_title: string;

    private routing_history: any[] = [];

    private _switch_support_chat: boolean;

    public get switch_support_chat(): boolean {
        return this._switch_support_chat;
    }
    public set switch_support_chat(value: boolean) {
        this._switch_support_chat = value;
    }

    set page_title(value) {
        this._page_title = value;
        this.titleService.setTitle(`${value} | Time Table Hillel`);
    }

    get page_title(): string {
        return this._page_title;
    }

    private _sidebar_opened = false;

    get sidebar_opened(): boolean {
        return this._sidebar_opened;
    }

    set sidebar_opened(value) {
        this._sidebar_opened = value;
        window.localStorage.setItem('sidebar_opened', `${value}`);
    }

    private renderer: Renderer2;

    constructor(
        private titleService: Title,
        private router: Router,
        private breakpointObserver: BreakpointObserver,
        private rendererFactory: RendererFactory2,
        private meta: Meta,
        private posthogService: PosthogService,
    ) {
        this._sidebar_opened =
            window.localStorage.getItem('sidebar_opened') === 'true';

        this.cvs_search
            .pipe(debounceTime(Constants.DEBOUNCE), distinctUntilChanged())
            .subscribe((query: string) => (this.cvs_last_search = query));

        this.renderer = this.rendererFactory.createRenderer(document, null); // Для работы  "Renderer2" в сервисе
    }

    public loadRouting(): void {
        this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationStart,
                ),
            )
            .subscribe((event: NavigationEnd | NavigationStart) => {
                if (event instanceof NavigationEnd) {
                    const { urlAfterRedirects, url } = event;

                    const queryParams =
                        this.router.parseUrl(urlAfterRedirects).queryParams;

                    this.routing_history.push([
                        [urlAfterRedirects.split('?')[0]],
                        {
                            queryParams,
                        },
                    ]);

                    this.routing_history = this.routing_history.slice(
                        Constants.ROUTER_HISTORY_BREAK,
                    );

                    this.url_support = false;
                    this.url_support_chat = false;
                    this.is_small_screen = false;

                    if (url === '/support') {
                        this.is_small_screen =
                            this.breakpointObserver.isMatched(
                                '(max-width: 768px)',
                            );
                    } else if (url.startsWith('/support')) {
                        this.url_support_chat = true;
                    }

                    if (url.startsWith('/support')) {
                        this.url_support = true;
                        this.renderer.setStyle(
                            document.body,
                            'min-width',
                            '320px',
                        );
                        this.meta.updateTag({
                            name: 'viewport',
                            content: 'width=device-width, initial-scale=1',
                        });
                    } else {
                        this.renderer.setStyle(
                            document.body,
                            'min-width',
                            '1280px',
                        );
                        this.meta.updateTag({
                            name: 'viewport',
                            content: 'width=1330',
                        });
                    }

                    this.posthogService.captureEvent('$pageview', {
                        $set: {
                            page_title: this.page_title,
                            page_path: urlAfterRedirects.replace(
                                /[a-f0-9]{24}/gi,
                                'object_id',
                            ),
                            page_url: urlAfterRedirects,
                        },
                    });
                } else if (event instanceof NavigationStart) {
                    if (event.url.includes('/v2')) {
                        this.router.navigateByUrl(event.url.replace('/v2', ''));
                    } else {
                        this.posthogService.captureEvent('$pageleave', {
                            $set: {
                                page_title: this.page_title,
                                page_path: this.router.url.replace(
                                    /[a-f0-9]{24}/gi,
                                    'object_id',
                                ),
                                page_url: this.router.url,
                            },
                        });
                    }
                }
            });
    }

    public getRoutingHistory(): string[] {
        return this.routing_history;
    }

    public getPreviousUrl(): NavigationExtras {
        return this.routing_history[this.routing_history.length - 2] || [['/']];
    }

    public reset(): void {
        this.user = undefined;
        this.school = undefined;
        this.permissions = undefined;
    }

    public addRequest(url: string, type: string): void {
        if (url.includes('/api/support/chats')) {
            if (this.switch_support_chat) this.requests.push(`${type}${url}`);
        } else {
            this.requests.push(`${type}${url}`);
        }
    }

    public removeRequest(url: string, type: string): void {
        this.requests = this.requests.filter(
            (item) => item !== `${type}${url}`,
        );
    }

    public createQueryArrayString(
        params: HttpParams,
        array: string[],
        param_string: string,
    ): HttpParams {
        return array.reduce(
            (acc: HttpParams, param) => acc.append(`${param_string}[]`, param),
            params,
        );
    }
}
