import { Component, inject } from '@angular/core';
import { GlobalService } from './services/global/global.service';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    private readonly matIconRegistry = inject(MatIconRegistry);

    constructor(private globalService: GlobalService) {
        this.matIconRegistry.setDefaultFontSetClass(
            'material-symbols-outlined',
        );
        this.globalService.loadRouting();
    }
}
