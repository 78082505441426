import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'seconds' })
export class SecondsPipe implements PipeTransform {
    public transform(seconds): any {
        if (seconds === 0) return '0';
        if (!seconds) return '';

        return moment.duration(seconds, 'seconds').asHours().toFixed(1);
    }
}
