import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { HttpService } from '../http/http.service';

@Injectable()
export class GroupsChatsService {
    constructor(private http: HttpService) {}

    public getGroupsChats({
        skip,
        limit,
        sort,
        direction,
        search,
    }: {
        skip: number;
        limit: number;
        sort: string;
        direction: number;
        search?: string;
    }): Observable<any> {
        let params = new HttpParams({
            fromObject: { skip, limit },
        });

        if (sort) {
            params = params.append('sort', sort);
        }

        if (direction) {
            params = params.append('direction', direction);
        }

        if (search) {
            params = params.append('search', search);
        }

        return this.http.get('/api/chats', { params });
    }

    public addParticipant(
        chat_id: string,
        participant_id: string,
    ): Observable<any> {
        return this.http.post(`/api/chats/${chat_id}`, { participant_id });
    }

    public deleteParticipant(
        chat_id: string,
        participant_id: string,
    ): Observable<any> {
        return this.http.delete(`/api/chats/${chat_id}/${participant_id}`);
    }

    public createGroupChat(data: {
        group_id?: string;
        title?: string;
        chat_type?: string;
        participants?: string[];
    }): Observable<any> {
        return this.http.post('/api/chats/create', data);
    }

    public getChat(chat_id: string): Observable<any> {
        return this.http.get(`/api/chats/${chat_id}`);
    }
}
