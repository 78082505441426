import {
    Directive,
    Input,
    OnChanges,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';

import { PermissionsService } from '@services/permissions/permissions.service';

@Directive({ selector: '[permission]' })
export class PermissionDirective implements OnChanges {
    @Input('permissionSchools') public schools: string[];
    @Input('permissionUser') public user: string;
    @Input('permission') public permission: string | boolean;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private permissionService: PermissionsService,
    ) {}

    public ngOnChanges(): void {
        this.viewContainer.clear();

        if (
            (typeof this.permission === 'string' &&
                this.permissionService.checkPermission({
                    name: this.permission,
                    user: this.user,
                    schools: this.schools,
                })) ||
            (typeof this.permission === 'boolean' && this.permission)
        ) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
