import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { GroupDatesChangelogResponseData } from '../../components/groups/group-changelog/group-changelog.constants';
import { GlobalService } from '../global/global.service';
import { HttpService, Response } from '../http/http.service';
import { encodeSpecialChars } from '@shared/common';
import { delay } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';

export enum StudentStatuses {
    active = 'student_status_active',
    freeze = 'student_statuses_freeze',
    unfreezed = 'student_statuses_unfreezed',
    transfer = 'student_statuses_transfer',
    removed = 'student_statuses_removed',
    refund = 'student_statuses_refund',
    reserved = 'student_statuses_reserved',
    limited = 'student_status_limited',
}

@Injectable()
export class GroupsService {
    constructor(
        private http: HttpService,
        public globalService: GlobalService,
    ) {}

    public addGroupV2(value: any): Observable<any> {
        return this.http.post(`/api/groups/detail`, value);
    }

    public getGroupV2(group_id: string): Observable<any> {
        return this.http.get(`/api/groups/detail/${group_id}`);
    }

    public getSchoolSchedule(
        school_id: string,
        status?: string,
        date?: Date | string,
    ): Observable<any> {
        let params = new HttpParams();

        if (status) {
            params = params.append('status', status);
        }

        if (date) {
            params = params.append(
                'date',
                moment(date).format('YYYY-MM-DD[T00:00:00.000Z]'),
            );
        }

        return this.http.get(`/api/groups/school/${school_id}/schedule`, {
            params,
        });
    }

    public removeGroup(group_id: string): Observable<any> {
        return this.http.delete(`/api/groups/${group_id}`);
    }

    public getGroups(query: {
        type: 'active' | 'archive' | 'recruit';
        skip: number;
        limit: number;
        direction: number;
        search: string;
        sort: string;
        coach_exists?: string[];
        courses?: string[];
        schools?: string[];
        start_date_range?: string[];
        end_date_range?: string[];
        categories?: string[];
        levels?: string[];
    }): Observable<any> {
        let params = new HttpParams({
            fromObject: {
                type: query.type,
                skip: query.skip,
                limit: query.limit,
                'coach_exists[]': query?.coach_exists || [],
                'courses[]': query?.courses || [],
                'schools[]': query?.schools || [],
                'start_date_range[]': query?.start_date_range || [],
                'end_date_range[]': query?.end_date_range || [],
                'categories[]': query?.categories || [],
                'levels[]': query?.levels || [],
            },
        });

        if (query.sort) {
            params = params.append('sort', query.sort);
        }

        if (query.direction) {
            params = params.append('direction', query.direction);
        }

        let url = '/api/groups';

        if (query.search) {
            url += `?search=${encodeSpecialChars(query.search)}`;
        }

        return this.http.get(url, { params });
    }

    public archiveGroup(group_id): Observable<any> {
        return this.http.get(`/api/groups/${group_id}/archive`);
    }

    public restoreGroup(group_id): Observable<any> {
        return this.http.get(`/api/groups/${group_id}/restore`);
    }

    public updateGroupNote(
        group_id: string,
        note_id: string,
        note: any,
    ): Observable<any> {
        return this.http.put(
            `/api/groups/detail/${group_id}/note/${note_id}`,
            note,
        );
    }

    public addGroupNote(_id: string, note: any): Observable<any> {
        return this.http.post(`/api/groups/detail/${_id}/note`, {
            note,
        });
    }

    public addNoteInGroupUser(
        group_id: string,
        user_id: string,
        note: string,
    ): Observable<any> {
        return this.http.post(
            `/api/groups/${group_id}/student/${user_id}/note`,
            {
                note,
            },
        );
    }

    public updateGroupV2(group_id: string, group: any): Observable<any> {
        return this.http.put(`/api/groups/detail/${group_id}`, group);
    }

    public getStudentDiscounts(
        group_id: string,
        student_id: string,
    ): Observable<any> {
        return this.http.get(
            `/api/groups/${group_id}/student/${student_id}/discounts`,
        );
    }

    public getInviteDiscounts(
        group_id: string,
        invite_id: string,
    ): Observable<any> {
        return this.http.get(
            invite_id
                ? `/api/groups/${group_id}/invite/${invite_id}/discounts`
                : `/api/groups/${group_id}/invite/discounts`,
        );
    }

    public calculateStudentPrice(
        group_id: string,
        student_id: string,
        value: any,
    ): Observable<any> {
        return this.http.post(
            `/api/groups/${group_id}/student/${student_id}/calculate_price`,
            {
                discounts: value,
            },
        );
    }

    public calculateInvitePrice(
        group_id: string,
        invite_id: string,
        value: any,
    ): Observable<any> {
        return this.http.post(
            invite_id
                ? `/api/groups/${group_id}/invite/${invite_id}/calculate_price`
                : `/api/groups/${group_id}/invite/calculate_price`,
            {
                discounts: value,
            },
        );
    }

    public updateStudentDiscounts(
        group_id: string,
        student_id: string,
        value: any,
    ): Observable<any> {
        return this.http.put(
            `/api/groups/${group_id}/student/${student_id}/discounts`,
            {
                discounts: value,
            },
        );
    }

    public updateStudentAdmin(
        group_id: string,
        student_id: string,
        admin_id,
    ): Observable<any> {
        return this.http.put(
            `/api/groups/${group_id}/student/${student_id}/admin`,
            {
                admin: admin_id,
            },
        );
    }

    public updateStudentContractDay(
        group_id: string,
        student_id: string,
        value: Object,
    ): Observable<any> {
        return this.http.post(
            `/api/groups/${group_id}/student/${student_id}/contract_date`,
            value,
        );
    }

    public reserveSeat(group_id: string, value): Observable<any> {
        return this.http.post(`/api/groups/${group_id}/reserve`, value);
    }

    public updateReserveSeat(
        reservation_id: string,
        group_id: string,
        value,
    ): Observable<any> {
        return this.http.put(
            `/api/groups/${group_id}/reserve/${reservation_id}`,
            value,
        );
    }

    public deleteReservationSeat(
        group_id: string,
        reserve_id: string,
    ): Observable<any> {
        return this.http.delete(
            `/api/groups/${group_id}/reserve/${reserve_id}`,
        );
    }

    public changeStudentStatus(
        group_id: string,
        student_id: string,
        status: StudentStatuses,
        options?: {
            new_group?: string;
            freeze_lesson?: number;
            unfreeze_lesson?: number;
            freeze_reason?: string;
            freeze_comment?: string;
            limit_lesson?: number;
        },
    ): Observable<any> {
        return this.http.put(
            `/api/groups/${group_id}/student/${student_id}/status`,
            {
                status,
                ...options,
            },
        );
    }

    public getGroupStudents(group_id: string, select = false): Observable<any> {
        let params = new HttpParams();
        params = params.append('select', `${select}`);

        return this.http.get(`/api/groups/${group_id}/students`, { params });
    }

    public checkGroup(group_id: string): Observable<any> {
        return this.http.get(`/api/groups/${group_id}/check`);
    }

    public deleteStudentFromGroup(
        student_id: string,
        group_id: string,
    ): Observable<any> {
        return this.http.delete(
            `/api/groups/${group_id}/student/${student_id}`,
        );
    }

    public startGroup(group_id: string, school_id: string): Observable<any> {
        return this.http.put(`/api/groups/${group_id}/start`, {
            school_id,
        });
    }

    public getStudentProfileGroups(user_id: string): Observable<any> {
        return this.http.get(`/api/groups/student/${user_id}/profile`);
    }

    public addStudentToGroup(
        group_id: string,
        value: Object,
        reservation_id = '',
    ): Observable<any> {
        let params = new HttpParams();

        if (reservation_id) {
            params = params.append('reservation_id', reservation_id);
        }

        return this.http.post(`/api/groups/${group_id}/students/add`, value, {
            params,
        });
    }

    public addStudentPayment(
        group_id: string,
        student_id: string,
        value,
    ): Observable<any> {
        return this.http.post(
            `/api/groups/${group_id}/student/${student_id}/payment`,
            value,
        );
    }

    public deleteStudentPayment(
        group_id: string,
        student_id: string,
        payment_id: string,
    ): Observable<any> {
        return this.http.delete(
            `/api/groups/${group_id}/student/${student_id}/payment/${payment_id}`,
        );
    }

    public editStudentPayment(
        group_id: string,
        student_id: string,
        payment_id: string,
        value,
    ): Observable<any> {
        return this.http.put(
            `/api/groups/${group_id}/student/${student_id}/payment/${payment_id}`,
            value,
        );
    }

    public updateStudentPaymentInfo(
        group_id: string,
        value: Object,
    ): Observable<any> {
        return this.http.put(
            `/api/groups/${group_id}/students/payments`,
            value,
        );
    }

    public changeStartDate(group_id: string, value: Object): Observable<any> {
        return this.http.post(
            `/api/groups/${group_id}/change-start-date`,
            value,
        );
    }

    public findGroup(
        search = '',
        types: Array<'active' | 'archive' | 'recruit' | 'any'> = ['any'],
        school_id = '',
        user_id?: string,
    ): Observable<any> {
        let params = new HttpParams();

        params = this.globalService.createQueryArrayString(
            params,
            types,
            'types',
        );

        if (search) {
            params = params.append('search', search);
        }

        if (school_id) {
            params = params.append('school_id', school_id);
        }

        if (user_id) {
            params = params.append('user_id', user_id);
        }

        return this.http.get(`/api/groups/find`, { params });
    }

    public getGroupsList({
        user_id,
        statuses, // 'active', 'archive', 'recruit'
    }: {
        user_id: string;
        statuses?: string[];
    }): Observable<any> {
        let params = new HttpParams();

        if (statuses && statuses.length) {
            params = this.globalService.createQueryArrayString(
                params,
                statuses,
                'status',
            );
        }

        if (user_id) {
            params = params.append('user', user_id);
        }

        return this.http.get(`/api/groups/list`, { params });
    }

    public getGroupInformation(group_id: string): Observable<any> {
        return this.http.get(`/api/groups/${group_id}/information`);
    }

    public sendLMSAccess(group_id: string): Observable<any> {
        return this.http.post(`/api/groups/${group_id}/send_lms_access`, {});
    }

    public createTelegramChat(group_id: string): Observable<any> {
        return this.http.post(`/api/telegram/create-chat/${group_id}`, {});
    }

    public sendGroupRating(group_id: string, value): Observable<any> {
        return this.http.post(`/api/groups/${group_id}/send_rating`, value);
    }

    public getGroupDebts(group_id: string): Observable<any> {
        return this.http.get(`/api/groups/${group_id}/debts`);
    }

    public getGroupRating(group_id: string): Observable<any> {
        return this.http.get(`/api/groups/${group_id}/get_rating`);
    }

    public getGroupEvents(group_id: string): Observable<any> {
        return this.http.get(`/api/groups/${group_id}/events`);
    }

    public updateGroupEvent(
        group_id: string,
        event_id: string,
        event: { checked: boolean; comment: string },
    ): Observable<any> {
        return this.http.put(
            `/api/groups/${group_id}/events/${event_id}`,
            event,
        );
    }

    public getGroupVisits(group_id: string): Observable<any> {
        return this.http.get(`/api/groups/${group_id}/visits`);
    }

    public setGroupVisit(value: {
        group_id: string;
        user_id: string;
        date: Date;
        visit: boolean;
    }): Observable<any> {
        return this.http.post(`/api/groups/${value.group_id}/visits`, value);
    }

    public getGroupRequirementsArchive(group_id: string): Observable<any> {
        return this.http.get(`/api/groups/${group_id}/requirements`);
    }

    public getCameraGroups(camera_id: string): Observable<any> {
        return this.http.get(`/api/groups/camera/${camera_id}`);
    }

    public createNoCoachTask(group_id: string): Observable<any> {
        return this.http.post(`/api/tasks/jira/no-coach`, { group_id });
    }

    public getGroupDatesChangelog(
        group_id: string,
    ): Observable<Response<GroupDatesChangelogResponseData>> {
        return this.http.get(`/api/groups/${group_id}/changelog/dates`);
    }

    public getGroupStudentsEmployments(group_id: string): Observable<any> {
        return this.http.get(`/api/groups/${group_id}/employments`);
    }

    public notifyGroup(data: any): Observable<any> {
        return this.http.post(`/api/group-notify/notify`, data);
    }

    public getNotifyHistory(
        skip: number,
        limit: number,
        group: string,
    ): Observable<any> {
        return this.http.get(
            `/api/group-notify/history?skip=${skip}&limit=${limit}&group=${group}`,
        );
    }

    public createRefund(data: any): Observable<any> {
        return this.http.post(`/api/refunds`, data);
    }

    public getRefunds(data: any): Observable<any> {
        return this.http.get(
            `/api/refunds/${data.group_id}/${data.student_id}`,
        );
    }

    public getReserves({
        skip,
        limit,
        schools,
        managers,
        sort,
        direction,
    }: {
        skip: number;
        limit: number;
        direction: number;
        sort: string;
        schools?: string[];
        managers?: string[];
    }): Observable<any> {
        const params = new HttpParams({
            fromObject: {
                skip,
                limit,
                direction,
                sort,
                'schools[]': schools || [],
                'managers[]': managers || [],
            },
        });

        return this.http.get(`/api/groups/reserve`, { params });
    }

    public getExpectedPaymentsAmount(): Observable<any> {
        return this.http.get('/api/groups/expected-payments-amount');
    }

    public transferGroupToSchool(
        group_id: string,
        school_id: string,
    ): Observable<any> {
        return this.http.post(`/api/groups/${group_id}/transfer`, {
            school_id,
        });
    }

    public findEmptyClass(value: {
        school_id: string;
        start_date: Date;
        lessons_all: number;
        days: any[];
    }): Observable<any> {
        return this.http.post('/api/groups/find/classes', value);
    }

    public removeRefund(refund_id: string): Observable<any> {
        return this.http.post(`/api/refunds/remove/${refund_id}`, {});
    }

    public getEdrpouCode(code: string): Observable<any> {
        return this.http.get(`/api/odb/legals/${code}/name`);
    }

    public getCoachPayments(coach_id: string): Observable<any> {
        return this.http.get(`/api/groups/coach/cost/${coach_id}`);
    }

    public getFilterPresets(): Observable<any> {
        return this.http.get('/api/groups/filter-presets');
    }

    public saveSelectedGroupFilter(courses: string[]): Observable<any> {
        return this.http.post('/api/action', {
            action_type: 'click',
            name: 'tt_groups_filter',
            courses,
        });
    }

    public autocompleteSearch(search: string): Observable<any> {
        const url = `/api/groups/autocomplete-search?search=${encodeSpecialChars(
            search,
        )}`;

        return this.http.get(url);
    }
}
