export enum LayoutConstant {
    MAX_LOCALS_LENGTH = 5,
}

let _user_id = '';
let _school_id = '';

export const setIds = (user_id, school_id): void => {
    _user_id = user_id;
    _school_id = school_id;
};

export const layoutMenu = (key): any => {
    return layoutLinks().find((item) => item.key === key) || null;
};

export const layoutLinks = (): any => {
    // required keys - title, icon, key, section
    // key - unique key for menu item
    // active_router - unique active router for menu item

    return [
        {
            title: 'page-tt-layout:my_profile',
            icon: 'person_pin',
            router: ['/', 'profiles', _user_id, 'information'],
            key: 'profile',
            section: 'profile',
        },
        {
            title: 'page-tt-layout:quick_access',
            icon: 'edit_note',
            type: 'quick_access',
            key: 'quick_access',
            section: 'profile',
        },
        {
            title: 'page-tt-layout:edit_profile',
            icon: 'manage_accounts',
            router: ['/', 'profiles', _user_id, 'edit'],
            key: 'edit_profile',
            section: 'profile',
        },
        {
            title: 'page-tt-layout:exit',
            icon: 'exit_to_app',
            type: 'exit',
            key: 'exit',
            section: 'profile',
        },
        {
            title: 'page-tt-layout:add_groups_dialog_item',
            icon: 'contacts',
            router: ['/', 'groups', 'add'],
            key: 'add_groups',
            section: 'add',
        },
        {
            title: 'page-tt-layout:add_course_dialog_item',
            icon: 'collections_bookmark',
            router: ['/', 'courses', 'add'],
            key: 'add_course',
            section: 'add',
        },
        {
            title: 'page-tt-layout:add_user_dialog_item',
            icon: 'account_circle',
            router: ['/', 'profiles', 'add'],
            key: 'add_user',
            section: 'add',
        },
        {
            title: 'page-tt-layout:add_video_course_dialog_item',
            icon: 'video_library',
            router: ['/', 'videocourses'],
            key: 'add_video_course',
            section: 'add',
        },
        {
            title: 'page-tt-layout:add_class_dialog_item',
            icon: 'contact_phone',
            router: ['/', 'classes'],
            key: 'add_class',
            section: 'add',
        },
        {
            title: 'page-tt-layout:add_fop_dialog_item',
            icon: 'perm_contact_calendar',
            router: ['/', 'fops', 'add'],
            key: 'add_fop',
            section: 'add',
        },
        {
            title: 'page-tt-layout:add_company_dialog_item',
            icon: 'copyright',
            router: ['/', 'companies'],
            key: 'add_company',
            section: 'add',
        },
        {
            title: 'page-tt-layout:schedule',
            icon: 'calendar_month',
            router: ['/', 'schedule', _school_id],
            key: 'schedule',
            section: 'education',
            active_router: 'schedule',
        },
        {
            title: 'page-tt-layout:broadcasts',
            icon: 'play_circle_filled',
            router: ['/', 'meetings-dashboard'],
            key: 'broadcasts',
            section: 'education',
            active_router: 'meetings-dashboard',
        },
        {
            title: 'page-tt-layout:classes',
            icon: 'meeting_room',
            router: ['/', 'classes'],
            key: 'classes',
            section: 'education',
            active_router: 'classes',
        },
        {
            title: 'page-tt-layout:sprints',
            icon: 'streetview',
            router: ['/', 'sprints'],
            key: 'sprints',
            section: 'groups',
            active_router: 'sprints',
        },
        {
            title: 'page-tt-layout:invite_student',
            icon: 'local_activity',
            router: ['/', 'invite'],
            key: 'invite',
            section: 'groups',
            active_router: 'invite',
        },
        {
            title: 'general:groups',
            icon: 'group',
            router: ['/', 'groups'],
            key: 'groups',
            section: 'groups',
            active_router: 'groups',
        },
        {
            title: 'page-tt-layout:reserve_in_groups',
            icon: 'lock',
            router: ['/', 'reserves'],
            params: {
                skip: 0,
                direction: 1,
                sort: 'reserve_terms_date',
            },
            key: 'reserve_in_groups',
            section: 'groups',
            active_router: 'reserves',
        },
        {
            title: 'page-tt-layout:keycalls',
            icon: 'phone',
            router: ['/', 'keycalls'],
            key: 'keycalls',
            section: 'groups',
            active_router: 'keycalls',
        },
        {
            title: 'page-tt-layout:group_chats_lms',
            icon: 'forum',
            router: ['/', 'groups-chats'],
            params: {
                skip: 0,
                direction: 1,
                sort: 'start_date',
            },
            key: 'group_chats_lms',
            section: 'groups',
            active_router: 'groups-chats',
        },
        {
            title: 'page-tt-layout:polls',
            icon: 'poll',
            router: ['/', 'polls'],
            key: 'polls',
            section: 'groups',
            active_router: 'polls',
        },
        {
            title: 'page-tt-layout:courses',
            icon: 'category',
            router: ['/', 'courses'],
            key: 'courses',
            section: 'courses',
            active_router: 'courses',
        },
        {
            title: 'page-tt-layout:programme',
            icon: 'chrome_reader_mode',
            router: ['/', 'programme'],
            params: {
                direction: 1,
                sort: 'createdAt',
            },
            key: 'programme',
            section: 'courses',
            active_router: 'programme',
        },
        {
            title: 'page-tt-layout:workbooks',
            icon: 'menu_book',
            router: ['/', 'workbooks'],
            key: 'workbooks',
            section: 'courses',
            active_router: 'workbooks',
        },
        {
            title: 'page-tt-layout:recommendations',
            icon: 'ballot',
            router: ['/', 'recommendations'],
            key: 'recommendations',
            section: 'courses',
            active_router: 'recommendations',
        },
        {
            title: 'page-tt-layout:course_events',
            icon: 'spellcheck',
            router: ['/', 'courses', 'events'],
            key: 'course_events',
            section: 'courses',
            active_router: 'events',
        },
        {
            title: 'page-tt-layout:video_courses',
            icon: 'video_library',
            router: ['/', 'videocourses'],
            key: 'video_courses',
            section: 'courses',
            active_router: 'videocourses',
        },
        {
            title: 'page-tt-layout:newsletters',
            icon: 'email',
            router: ['/', 'mailings'],
            key: 'newsletters',
            section: 'courses',
            active_router: 'mailings',
        },
        {
            title: 'page-tt-layout:code_tasks',
            icon: 'check_circle',
            router: ['/', 'code-tasks'],
            key: 'code_tasks',
            section: 'courses',
            active_router: 'code-tasks',
        },
        {
            title: 'page-tt-layout:quiz_title',
            icon: 'quiz',
            router: ['/', 'quiz'],
            key: 'quiz',
            section: 'courses',
            active_router: 'quiz',
        },
        {
            title: 'general:support',
            icon: 'contact_support',
            router: ['/', 'support'],
            key: 'support',
            section: 'people',
            active_router: 'support',
        },
        {
            title: 'general:achievements',
            icon: 'workspace_premium',
            router: ['/', 'achievements'],
            key: 'achievements',
            section: 'people',
            active_router: 'achievements',
        },
        {
            title: 'page-tt-layout:freeze_students',
            icon: 'hourglass_full',
            router: ['/', 'freeze-student'],
            key: 'freeze_students',
            section: 'people',
            active_router: 'freeze-student',
        },
        {
            title: 'page-tt-layout:limited_students',
            icon: 'not_interested',
            router: ['/', 'limited-student'],
            key: 'limited_students',
            section: 'people',
            active_router: 'limited-student',
        },
        {
            title: 'general:users',
            icon: 'people_outline',
            router: ['/', 'users'],
            key: 'users',
            section: 'people',
            active_router: 'users',
        },
        {
            title: 'page-tt-layout:teachers',
            icon: 'school',
            router: ['/', 'coaches'],
            params: { skip: 0, direction: 1 },
            key: 'teachers',
            section: 'people',
            active_router: 'coaches',
        },
        {
            title: 'page-tt-layout:birthdays',
            icon: 'cake',
            router: ['/', 'birthdays'],
            key: 'birthdays',
            section: 'people',
            active_router: 'birthdays',
        },
        {
            title: 'page-tt-layout:base_of_certificates',
            icon: 'featured_play_list',
            router: ['/', 'certificates'],
            key: 'base_of_certificates',
            section: 'people',
            active_router: 'certificates',
        },
        {
            title: 'page-tt-layout:merging_accounts',
            icon: 'supervised_user_circle',
            router: ['/', 'merge'],
            key: 'merging_accounts',
            section: 'people',
            active_router: 'merge',
        },
        {
            title: 'page-tt-layout:correction_requests',
            icon: 'assignment_late',
            router: ['/', 'confirms'],
            key: 'correction_requests',
            section: 'people',
            active_router: 'confirms',
        },
        {
            title: 'page-tt-layout:fops',
            icon: 'payment',
            router: ['/', 'fops'],
            key: 'fops',
            section: 'money',
            active_router: 'fops',
        },
        {
            title: 'page-tt-layout:budget',
            icon: 'import_contacts',
            router: ['/', 'budget'],
            key: 'budget',
            section: 'money',
            active_router: 'budget',
        },
        {
            title: 'page-tt-layout:promocode',
            icon: 'subtitles',
            router: ['/', 'coupon'],
            key: 'promocode',
            section: 'money',
            active_router: 'coupon',
        },
        {
            title: 'page-tt-layout:cash',
            icon: 'attach_money',
            router: ['/', 'cashbox'],
            key: 'cashbox',
            section: 'money',
            active_router: 'cashbox',
        },
        {
            title: 'page-tt-layout:categories_of_expenses',
            icon: 'assignment',
            router: ['/', 'cashbox-categories'],
            key: 'categories_of_expenses',
            section: 'money',
            active_router: 'cashbox-categories',
        },
        {
            title: 'page-tt-layout:payments',
            icon: 'how_to_vote',
            router: ['/', 'payments'],
            key: 'payments',
            section: 'money',
            active_router: 'payments',
        },
        {
            title: 'page-tt-layout:return_applications',
            icon: 'description',
            router: ['/', 'refund-statements'],
            params: { skip: 0, direction: 1, sort: 'createdAt' },
            key: 'return_applications',
            section: 'money',
            active_router: 'refund-statements',
        },
        {
            title: 'page-tt-layout:withdrawals',
            icon: 'account_balance_wallet',
            router: ['/', 'withdrawals'],
            key: 'withdrawals',
            section: 'money',
            active_router: 'withdrawals',
        },
        {
            title: 'page-tt-layout:excerpts',
            icon: 'receipt',
            router: ['/', 'odb-fops'],
            key: 'excerpts',
            section: 'money',
            active_router: 'odb-fops',
        },
        {
            title: 'page-tt-layout:expected_payments_amount',
            icon: 'receipt',
            router: ['/', 'expected-payments-amount'],
            key: 'expected_payments_amount',
            section: 'money',
            active_router: 'expected-payments-amount',
        },
        {
            title: 'page-tt-layout:referrals',
            icon: 'supervisor_account',
            router: ['/', 'referrals'],
            key: 'referrals',
            section: 'money',
            active_router: 'referrals',
        },
        {
            title: 'page-tt-layout:vpo',
            icon: 'location_on',
            router: ['/', 'vpo'],
            params: {
                skip: 0,
                limit: 40,
                status: 'confirmed,filled',
            },
            key: 'vpo',
            section: 'money',
            active_router: 'vpo',
        },
        {
            title: 'page-tt-layout:debtors',
            icon: 'money_off',
            router: ['/', 'debtors'],
            key: 'debtors',
            section: 'money',
            active_router: 'debtors',
        },
        {
            title: 'page-tt-layout:customer_balances',
            icon: 'account_balance_wallet',
            router: ['/', 'customer-balances'],
            key: 'customer_balances',
            section: 'money',
            active_router: 'customer-balances',
        },
        {
            title: 'page-tt-layout:purchases',
            icon: 'shopping_cart',
            router: ['/', 'purchases'],
            key: 'purchases',
            section: 'money',
            active_router: 'purchases',
        },
        {
            title: 'page-tt-layout:interviews',
            icon: 'people',
            router: ['/', 'interviews'],
            key: 'interviews',
            section: 'hr',
            active_router: 'interviews',
        },
        {
            title: 'page-tt-layout:resume_base',
            icon: 'assignment_ind',
            router: ['/', 'cvs'],
            key: 'resume_base',
            section: 'hr',
            active_router: 'cvs',
        },
        {
            title: 'page-tt-layout:skills_title',
            icon: 'fitness_center',
            router: ['/', 'skills'],
            key: 'skills',
            section: 'hr',
            active_router: 'skills',
        },
        {
            title: 'page-tt-layout:company',
            icon: 'style',
            router: ['/', 'companies'],
            key: 'companies',
            section: 'hr',
            active_router: 'companies',
        },
        {
            title: 'page-tt-layout:vacancies',
            icon: 'work',
            router: ['/', 'vacancies'],
            key: 'vacancies',
            section: 'hr',
            active_router: 'vacancies',
        },
        {
            title: 'page-tt-layout:employment',
            icon: 'check_circle',
            router: ['/', 'students-employment'],
            key: 'students_employment',
            section: 'hr',
            active_router: 'students-employment',
        },
        {
            title: 'page-tt-layout:checklist',
            icon: 'checklist',
            router: ['/', 'checklist'],
            key: 'checklist',
            section: 'hr',
            active_router: 'checklist',
        },
        {
            title: 'page-tt-layout:longlist',
            icon: 'local_activity',
            router: ['/', 'longlist'],
            key: 'long_list',
            section: 'hr',
            active_router: 'longlist',
        },
        {
            title: 'page-tt-layout:personal_consultations',
            icon: '3p',
            router: ['/', 'consultations-list'],
            key: 'consultations',
            section: 'consultations',
            active_router: 'consultations-list',
        },
        {
            title: 'page-tt-layout:consultations_cancellations',
            icon: 'free_cancellation',
            router: ['/', 'consultations', 'cancellations'],
            key: 'consultations_cancellations',
            section: 'consultations',
            active_router: 'cancellations',
        },
        {
            title: 'page-tt-layout:consultations_coaches',
            icon: 'assignment_ind',
            router: ['/', 'consultations', 'consultants'],
            key: 'consultations_coaches',
            section: 'consultations',
            active_router: 'consultants',
        },
        {
            title: 'page-tt-layout:consultations_topics',
            icon: 'label',
            router: ['/', 'consultations', 'topics'],
            key: 'consultations_topics',
            section: 'consultations',
            active_router: 'topics',
        },
        {
            title: 'page-tt-layout:schools',
            icon: 'apartment',
            router: ['/', 'schools'],
            key: 'schools',
            section: 'statistics',
            active_router: 'schools',
        },
        {
            title: 'page-tt-layout:loading_courses',
            icon: 'category',
            router: ['/', 'statistics', 'courses'],
            key: 'loading_courses',
            section: 'statistics',
            active_router: 'statistics',
        },
        {
            title: 'page-tt-layout:start_accuracy',
            icon: 'slow_motion_video',
            router: ['/', 'statistics', 'delay'],
            key: 'start_accuracy',
            section: 'statistics',
            active_router: 'delay',
        },
        {
            title: 'page-tt-layout:statistics_support',
            icon: 'support_agent',
            router: ['/', 'statistics', 'support'],
            key: 'support_statistics',
            section: 'statistics',
            active_router: 'statistics',
        },
        {
            title: 'page-tt-layout:nps',
            icon: 'how_to_vote',
            router: ['/', 'statistics', 'nps'],
            params: { role_group: 'student' },
            key: 'nps',
            section: 'statistics',
            active_router: 'nps',
        },
        {
            title: 'page-tt-layout:cnps',
            icon: 'how_to_vote',
            router: ['/', 'statistics', 'nps'],
            params: { role_group: 'teacher' },
            key: 'cnps',
            section: 'statistics',
            active_router: 'nps',
        },
        {
            title: 'page-tt-layout:school_employment',
            icon: 'location_city',
            router: ['/', 'statistics', 'employment', 'schools'],
            key: 'school_employment',
            section: 'statistics',
            active_router: 'statistics',
        },
        {
            title: 'page-tt-layout:employment_courses',
            icon: 'school',
            router: ['/', 'statistics', 'employment', 'courses'],
            key: 'employment_courses',
            section: 'statistics',
            active_router: 'statistics',
        },
        {
            title: 'page-tt-layout:average_course_duration',
            icon: 'hourglass_empty',
            router: ['/', 'statistics', 'average'],
            key: 'average_course_duration',
            section: 'statistics',
            active_router: 'average',
        },
        {
            title: 'page-tt-layout:resume_statistics',
            icon: 'contacts',
            router: ['/', 'statistics', 'cvs'],
            key: 'cvs_statistics',
            section: 'statistics',
            active_router: 'statistics',
        },
        {
            title: 'page-tt-layout:performance',
            icon: 'transfer_within_a_station',
            router: ['/', 'statistics', 'coaches', 'rating'],
            key: 'performance',
            section: 'statistics',
            active_router: 'statistics',
        },
        {
            title: 'page-tt-layout:statistics_app',
            icon: 'phone_android',
            router: ['/', 'statistics', 'app'],
            key: 'statistics_app',
            section: 'statistics',
            active_router: 'statistics',
        },
        {
            title: 'page-tt-layout:app_updates',
            icon: 'history',
            router: ['/', 'app-history'],
            key: 'app_updates',
            section: 'settings',
            active_router: 'app-history',
        },
        {
            title: 'page-tt-layout:lms_updates',
            icon: 'update',
            router: ['/', 'updates'],
            key: 'lms_updates',
            section: 'settings',
            active_router: 'updates',
        },
        {
            title: 'page-tt-layout:localization',
            icon: 'local_library',
            router: ['/', 'localizations'],
            key: 'localization',
            section: 'settings',
            active_router: 'localizations',
        },
        {
            title: 'page-tt-layout:tasks_settings',
            icon: 'watch',
            router: ['/', 'tasks'],
            key: 'tasks_settings',
            section: 'settings',
            active_router: 'tasks',
        },
        {
            title: 'page-tt-layout:mail_settings_title',
            icon: 'mail_outline',
            router: ['/', 'mail-config'],
            key: 'mail_settings',
            section: 'settings',
            active_router: 'mail-config',
        },
        {
            title: 'page-tt-layout:type_polls',
            icon: 'toc',
            router: ['/', 'questions'],
            key: 'type_polls',
            section: 'settings',
            active_router: 'questions',
        },
        {
            title: 'page-tt-layout:store_title',
            icon: 'shopping_basket',
            router: ['/', 'store'],
            key: 'store',
            section: 'settings',
            active_router: 'store',
        },
        {
            title: 'page-tt-layout:links_parser',
            icon: 'link',
            router: ['/', 'parser'],
            key: 'links_parser',
            section: 'settings',
            active_router: 'parser',
        },
        {
            title: 'page-tt-layout:action_system',
            icon: 'toggle_on',
            router: ['/', 'system-control'],
            key: 'system_control',
            section: 'settings',
            active_router: 'system-control',
        },
        {
            title: 'Material UI',
            icon: 'scatter_plot',
            router: ['/', 'material-ui'],
            key: 'material_ui',
            section: 'settings',
            active_router: 'material-ui',
        },
        {
            title: 'page-tt-layout:help',
            icon: 'live_help',
            router: ['/', 'help'],
            key: 'help',
            section: 'help',
            active_router: 'help',
        },
        {
            title: 'page-tt-layout:coach_instruction',
            icon: 'list_alt',
            router: ['/', 'coach-instruction'],
            key: 'coach_instruction',
            section: 'help',
            active_router: 'coach-instruction',
        },
        {
            title: 'page-tt-layout:video',
            icon: 'videocam',
            router: ['/', 'videos', _school_id, 'cameras'],
            key: 'video',
            section: 'others',
            active_router: 'videos',
        },
        {
            title: 'page-tt-layout:password_reset_logs',
            icon: 'lock_reset',
            router: ['/', 'password-reset-logs'],
            key: 'password_reset_logs',
            section: 'others',
            active_router: 'password-reset-logs',
        },
    ];
};
