import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { Observable } from 'rxjs';

@Injectable()
export class TokenService {
    constructor(private http: HttpService) {}

    public checkPasswordToken(token: string): Observable<any> {
        return this.http.get(`/api/tokens/restore-password/${token}`);
    }
}
