import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'get' })
export class GetPropertyPipe implements PipeTransform {
    public transform(
        value: { [key: string]: string },
        path: string | Array<string>,
        default_value = '',
    ): string {
        return _.get(value, path, default_value);
    }
}
