<div class="layout">
    <mat-drawer-container class="layout">
        <mat-drawer
            [disableClose]="true"
            #mainDrawer
            mode="side"
            opened
            class="layout__sidebar"
            [class.layout__sidebar--opened]="opened_main_menu"
        >
            <div
                class="layout__content"
                [ngClass]="{
                    'layout__content--open-sidebar':
                        opened_main_menu && !url_support,
                    'layout__content--mobile-sidebar':
                        opened_main_menu && url_support,
                }"
            >
                <ul
                    class="layout__menu"
                    [class.layout__menu--long]="inner_menu_height <= 178"
                >
                    <li
                        class="layout__menu-header"
                        #header_menu
                    >
                        <button
                            class="layout__menu-button"
                            (click)="opened_main_menu = !opened_main_menu"
                        >
                            <mat-icon class="layout__menu-icon">menu</mat-icon>
                        </button>
                        <a
                            class="layout__menu-title layout__nav-text"
                            [routerLink]="['/', 'schedule', school?._id]"
                        ></a>
                    </li>

                    <!-- Quick access -->
                    <li
                        class="layout__menu-access"
                        #access_menu
                    >
                        <ul
                            class="layout__menu-access-list"
                            [ngClass]="{
                                'layout__menu-access-list--triple':
                                    !permissions.search ||
                                    !permissions.menu_add,
                                'layout__menu-access-list--dual':
                                    !permissions.search &&
                                    !permissions.menu_add,
                            }"
                        >
                            <!-- User -->
                            <li
                                class="layout__menu-access-item"
                                [matMenuTriggerFor]="menu_user"
                            >
                                <app-avatar
                                    class="layout__menu-access-item-avatar layout__menu-access-item-sub"
                                    [class.layout__menu-access-item-avatar--online]="
                                        online
                                    "
                                    [images]="user?.avatars"
                                    thumb="20"
                                ></app-avatar>
                            </li>

                            <!-- Search -->
                            <li
                                *ngIf="permissions.search"
                                class="layout__menu-access-item"
                            >
                                <a
                                    class="layout__menu-access-item-sub"
                                    [routerLink]="['/', 'search']"
                                >
                                    <mat-icon class="layout__menu-icon"
                                        >search</mat-icon
                                    >
                                </a>
                            </li>

                            <li
                                class="layout__menu-access-item"
                                [matTooltip]="
                                    (operating_system === 'macos'
                                        ? 'page-tt-layout:search_by_menu_macos'
                                        : 'page-tt-layout:search_by_menu_others'
                                    ) | i18next
                                "
                                [matTooltipPosition]="'after'"
                            >
                                <button
                                    class="layout__menu-access-item-sub"
                                    (click)="openQuickSearchDialog()"
                                >
                                    <mat-icon class="layout__menu-icon">
                                        manage_search
                                    </mat-icon>
                                </button>
                            </li>

                            <!-- Add -->
                            <li
                                *ngIf="permissions.menu_add"
                                class="layout__menu-access-item"
                                [matMenuTriggerFor]="menu_add"
                            >
                                <mat-icon
                                    class="layout__menu-icon layout__menu-icon--add layout__menu-access-item-sub"
                                >
                                    add_circle
                                </mat-icon>
                            </li>
                        </ul>
                    </li>

                    <mat-menu
                        #menu_user="matMenu"
                        [overlapTrigger]="false"
                    >
                        <ul class="layout__menu-mat">
                            <!-- My profile -->
                            <li class="layout__menu-mat-link">
                                <a
                                    class="layout__menu-mat-route"
                                    [routerLink]="[
                                        '/',
                                        'profiles',
                                        user?._id,
                                        'information',
                                    ]"
                                >
                                    <mat-icon class="layout__menu-icon"
                                        >person_pin</mat-icon
                                    >
                                    <span class="layout__menu-mat-text">
                                        {{
                                            'page-tt-layout:my_profile'
                                                | i18next
                                        }}
                                    </span>
                                </a>
                                <ng-container
                                    [ngTemplateOutlet]="button"
                                    [ngTemplateOutletContext]="{
                                        $implicit: getLayoutMenu('profile'),
                                    }"
                                ></ng-container>
                            </li>

                            <!-- Edit profile -->
                            <li class="layout__menu-mat-link">
                                <a
                                    class="layout__menu-mat-route"
                                    [routerLink]="[
                                        '/',
                                        'profiles',
                                        user?._id,
                                        'edit',
                                    ]"
                                >
                                    <mat-icon class="layout__menu-icon">
                                        manage_accounts
                                    </mat-icon>
                                    <span class="layout__menu-mat-text">
                                        {{
                                            'page-tt-layout:edit_profile'
                                                | i18next
                                        }}
                                    </span>
                                </a>
                                <ng-container
                                    [ngTemplateOutlet]="button"
                                    [ngTemplateOutletContext]="{
                                        $implicit:
                                            getLayoutMenu('edit_profile'),
                                    }"
                                ></ng-container>
                            </li>

                            <!-- Quick access -->
                            <li class="layout__menu-mat-link">
                                <button
                                    class="layout__menu-mat-route"
                                    (click)="openQuickAccessDialog()"
                                >
                                    <mat-icon class="layout__menu-icon">
                                        edit_note
                                    </mat-icon>
                                    <span class="layout__menu-mat-text">
                                        {{
                                            'page-tt-layout:quick_access'
                                                | i18next
                                        }}
                                    </span>
                                </button>
                                <ng-container
                                    [ngTemplateOutlet]="button"
                                    [ngTemplateOutletContext]="{
                                        $implicit:
                                            getLayoutMenu('quick_access'),
                                    }"
                                ></ng-container>
                            </li>

                            <!-- Exit -->
                            <li class="layout__menu-mat-link">
                                <a
                                    class="layout__menu-mat-route"
                                    (click)="logout()"
                                >
                                    <mat-icon
                                        class="layout__menu-icon layout__menu-icon--exit"
                                    >
                                        exit_to_app
                                    </mat-icon>
                                    <span
                                        class="layout__menu-mat-text layout__menu-mat-text--exit"
                                    >
                                        {{ 'page-tt-layout:exit' | i18next }}
                                    </span>
                                </a>
                                <ng-container
                                    [ngTemplateOutlet]="button"
                                    [ngTemplateOutletContext]="{
                                        $implicit: getLayoutMenu('exit'),
                                    }"
                                ></ng-container>
                            </li>
                        </ul>
                    </mat-menu>

                    <mat-menu
                        #menu_add="matMenu"
                        [overlapTrigger]="false"
                    >
                        <ul class="layout__menu-mat">
                            <!-- Add groups -->
                            <li
                                class="layout__menu-mat-link"
                                *permission="
                                    'groups_add_edit';
                                    schools: [school._id]
                                "
                            >
                                <a
                                    class="layout__menu-mat-route"
                                    [routerLink]="['/', 'groups', 'add']"
                                >
                                    <mat-icon class="layout__menu-icon">
                                        contacts
                                    </mat-icon>
                                    <span
                                        class="layout__menu-mat-text layout__menu-mat-text--add"
                                    >
                                        {{
                                            'page-tt-layout:add_groups'
                                                | i18next
                                        }}
                                    </span>
                                </a>
                                <ng-container
                                    [ngTemplateOutlet]="button"
                                    [ngTemplateOutletContext]="{
                                        $implicit: getLayoutMenu('add_groups'),
                                    }"
                                ></ng-container>
                            </li>

                            <!-- Add course -->
                            <li
                                class="layout__menu-mat-link"
                                *permission="'courses_add_edit'"
                            >
                                <a
                                    class="layout__menu-mat-route"
                                    [routerLink]="['/', 'courses', 'add']"
                                >
                                    <mat-icon class="layout__menu-icon">
                                        collections_bookmark
                                    </mat-icon>
                                    <span
                                        class="layout__menu-mat-text layout__menu-mat-text--add"
                                    >
                                        {{
                                            'page-tt-layout:add_course'
                                                | i18next
                                        }}
                                    </span>
                                </a>
                                <ng-container
                                    [ngTemplateOutlet]="button"
                                    [ngTemplateOutletContext]="{
                                        $implicit: getLayoutMenu('add_course'),
                                    }"
                                ></ng-container>
                            </li>

                            <!-- Add user -->
                            <li
                                class="layout__menu-mat-link"
                                *permission="'users_add'"
                            >
                                <a
                                    class="layout__menu-mat-route"
                                    [routerLink]="['/', 'profiles', 'add']"
                                >
                                    <mat-icon class="layout__menu-icon">
                                        account_circle
                                    </mat-icon>
                                    <span
                                        class="layout__menu-mat-text layout__menu-mat-text--add"
                                    >
                                        {{
                                            'page-tt-layout:add_user' | i18next
                                        }}
                                    </span>
                                </a>
                                <ng-container
                                    [ngTemplateOutlet]="button"
                                    [ngTemplateOutletContext]="{
                                        $implicit: getLayoutMenu('add_user'),
                                    }"
                                ></ng-container>
                            </li>

                            <!-- Video courses -->
                            <li
                                class="layout__menu-mat-link"
                                *permission="'video-courses'"
                            >
                                <a
                                    class="layout__menu-mat-route"
                                    [routerLink]="['/', 'videocourses']"
                                >
                                    <mat-icon class="layout__menu-icon">
                                        video_library
                                    </mat-icon>
                                    <span class="layout__menu-mat-text">
                                        {{
                                            'page-tt-layout:video' | i18next
                                        }}-{{
                                            'page-tt-layout:add_course'
                                                | i18next
                                        }}
                                    </span>
                                </a>
                                <ng-container
                                    [ngTemplateOutlet]="button"
                                    [ngTemplateOutletContext]="{
                                        $implicit:
                                            getLayoutMenu('add_video_course'),
                                    }"
                                ></ng-container>
                            </li>

                            <!-- Classes -->
                            <li
                                class="layout__menu-mat-link"
                                *permission="'classes'"
                            >
                                <a
                                    class="layout__menu-mat-route"
                                    [routerLink]="['/', 'classes']"
                                >
                                    <mat-icon class="layout__menu-icon">
                                        contact_phone
                                    </mat-icon>
                                    <span
                                        class="layout__menu-mat-text layout__menu-mat-text--add"
                                    >
                                        {{
                                            'page-tt-layout:add_class' | i18next
                                        }}
                                    </span>
                                </a>
                                <ng-container
                                    [ngTemplateOutlet]="button"
                                    [ngTemplateOutletContext]="{
                                        $implicit: getLayoutMenu('add_class'),
                                    }"
                                ></ng-container>
                            </li>

                            <!-- Add fop -->
                            <li
                                class="layout__menu-mat-link"
                                *permission="'fops_add'"
                            >
                                <a
                                    class="layout__menu-mat-route"
                                    [routerLink]="['/', 'fops', 'add']"
                                >
                                    <mat-icon class="layout__menu-icon">
                                        perm_contact_calendar
                                    </mat-icon>
                                    <span class="layout__menu-mat-text">
                                        {{ 'page-tt-layout:add_fop' | i18next }}
                                    </span>
                                </a>
                                <ng-container
                                    [ngTemplateOutlet]="button"
                                    [ngTemplateOutletContext]="{
                                        $implicit: getLayoutMenu('add_fop'),
                                    }"
                                ></ng-container>
                            </li>

                            <!-- Add company  -->
                            <li
                                class="layout__menu-mat-link"
                                *permission="
                                    'company_control' && 'company_view'
                                "
                            >
                                <a
                                    class="layout__menu-mat-route"
                                    [routerLink]="['/', 'companies']"
                                >
                                    <mat-icon class="layout__menu-icon">
                                        copyright
                                    </mat-icon>
                                    <span
                                        class="layout__menu-mat-text layout__menu-mat-text--add"
                                    >
                                        {{
                                            'page-tt-layout:add_company'
                                                | i18next
                                        }}
                                    </span>
                                </a>
                                <ng-container
                                    [ngTemplateOutlet]="button"
                                    [ngTemplateOutletContext]="{
                                        $implicit: getLayoutMenu('add_company'),
                                    }"
                                ></ng-container>
                            </li>
                        </ul>
                    </mat-menu>

                    <!-- Pinned links -->
                    <li
                        *ngIf="pinned_links_array?.length"
                        (scroll)="onScroll($event, 'links')"
                        #scrolled_pinned_block
                        class="layout__menu-pinned"
                        [style.overflow]="
                            inner_menu_height > 177 ? 'auto' : 'none'
                        "
                        [class.layout__menu-pinned--long]="
                            inner_menu_height < 178
                        "
                    >
                        <ul #pinned_links>
                            <li
                                *ngFor="let link of pinned_links_array"
                                class="layout__nav-link layout__nav-link--pinned"
                                routerLinkActive="layout__nav-link--active"
                                (click)="setActiveDrawer(link.section)"
                            >
                                <button
                                    *ngIf="
                                        link.type === 'quick_access';
                                        else links
                                    "
                                    class="layout__nav-route layout__nav-route--pinned"
                                    (click)="openQuickAccessDialog()"
                                >
                                    <ng-container
                                        [ngTemplateOutlet]="link_content"
                                    ></ng-container>
                                </button>
                                <ng-template #links>
                                    <a
                                        *ngIf="
                                            link.type === 'exit';
                                            else another_type
                                        "
                                        class="layout__nav-route layout__nav-route--pinned"
                                        (click)="logout()"
                                    >
                                        <ng-container
                                            [ngTemplateOutlet]="link_content"
                                        ></ng-container>
                                    </a>

                                    <ng-template #another_type>
                                        <a
                                            class="layout__nav-route layout__nav-route--pinned"
                                            [routerLink]="link.router"
                                            [queryParams]="
                                                link.params ? link.params : null
                                            "
                                        >
                                            <ng-container
                                                [ngTemplateOutlet]="
                                                    link_content
                                                "
                                            ></ng-container>
                                        </a>
                                    </ng-template>
                                </ng-template>
                                <ng-template #link_content>
                                    <mat-icon
                                        class="layout__menu-icon"
                                        [class.layout__menu-icon--exit]="
                                            link.type === 'exit'
                                        "
                                    >
                                        {{ link.icon }}
                                    </mat-icon>
                                    <span
                                        class="layout__nav-text"
                                        [class.layout__nav-text--exit]="
                                            link.type === 'exit'
                                        "
                                    >
                                        {{ link.title | i18next }}
                                    </span>
                                </ng-template>
                            </li>
                        </ul>
                    </li>

                    <li class="layout__actions">
                        <ul>
                            <li
                                (scroll)="onScroll($event, 'actions')"
                                class="layout__nav-item layout__nav-item--block"
                                [class.layout__menu-pinned--long]="
                                    inner_menu_height <= real_inner_block_height
                                "
                                #scrolled_actions_block
                                [style.height]="
                                    inner_menu_height > real_inner_block_height
                                        ? 'auto'
                                        : inner_menu_height < 178
                                          ? '177px'
                                          : inner_menu_height + 'px'
                                "
                            >
                                <!-- Education -->
                                <div
                                    (click)="toggleDrawer('education')"
                                    class="layout__nav-link layout__nav-link--sub"
                                    [ngClass]="{
                                        'layout__nav-link--active':
                                            isActiveRoute(
                                                router_arrays.education
                                            ),
                                        'layout__nav-link--active-link':
                                            active_drawer === 'education' &&
                                            last_active_drawer === 'open',
                                    }"
                                >
                                    <mat-icon class="layout__menu-icon"
                                        >book</mat-icon
                                    >
                                    <span class="layout__nav-text">
                                        {{ 'general:education' | i18next }}
                                    </span>
                                </div>

                                <!-- Groups -->
                                <div
                                    *ngIf="permissions.groups"
                                    (click)="toggleDrawer('groups')"
                                    class="layout__nav-link layout__nav-link--sub"
                                    [ngClass]="{
                                        'layout__nav-link--active':
                                            isActiveRoute(router_arrays.groups),
                                        'layout__nav-link--active-link':
                                            active_drawer === 'groups' &&
                                            last_active_drawer === 'open',
                                    }"
                                >
                                    <mat-icon class="layout__menu-icon"
                                        >contacts</mat-icon
                                    >
                                    <span class="layout__nav-text">
                                        {{ 'general:groups' | i18next }}
                                    </span>
                                </div>

                                <!-- Courses -->
                                <div
                                    *ngIf="permissions.courses"
                                    (click)="toggleDrawer('courses')"
                                    class="layout__nav-link layout__nav-link--sub"
                                    [ngClass]="{
                                        'layout__nav-link--active':
                                            isActiveRoute(
                                                router_arrays.courses
                                            ),
                                        'layout__nav-link--active-link':
                                            active_drawer === 'courses' &&
                                            last_active_drawer === 'open',
                                    }"
                                >
                                    <mat-icon class="layout__menu-icon">
                                        collections_bookmark
                                    </mat-icon>
                                    <span class="layout__nav-text">
                                        {{ 'page-tt-layout:courses' | i18next }}
                                    </span>
                                </div>

                                <!-- People -->
                                <div
                                    (click)="toggleDrawer('people')"
                                    class="layout__nav-link layout__nav-link--sub"
                                    [ngClass]="{
                                        'layout__nav-link--active':
                                            isActiveRoute(router_arrays.people),
                                        'layout__nav-link--active-link':
                                            active_drawer === 'people' &&
                                            last_active_drawer === 'open',
                                    }"
                                >
                                    <mat-icon class="layout__menu-icon"
                                        >group</mat-icon
                                    >
                                    <span class="layout__nav-text">
                                        {{ 'general:people' | i18next }}
                                    </span>
                                </div>

                                <!-- Money -->
                                <div
                                    (click)="toggleDrawer('money')"
                                    class="layout__nav-link layout__nav-link--sub"
                                    [ngClass]="{
                                        'layout__nav-link--active':
                                            isActiveRoute(router_arrays.money),
                                        'layout__nav-link--active-link':
                                            active_drawer === 'money' &&
                                            last_active_drawer === 'open',
                                    }"
                                >
                                    <mat-icon class="layout__menu-icon"
                                        >paid</mat-icon
                                    >
                                    <span class="layout__nav-text">
                                        {{ 'general:money' | i18next }}
                                    </span>
                                </div>

                                <!-- HR -->
                                <div
                                    *ngIf="permissions.hr"
                                    (click)="toggleDrawer('hr')"
                                    class="layout__nav-link layout__nav-link--sub"
                                    [ngClass]="{
                                        'layout__nav-link--active':
                                            isActiveRoute(router_arrays.hr),
                                        'layout__nav-link--active-link':
                                            active_drawer === 'hr' &&
                                            last_active_drawer === 'open',
                                    }"
                                >
                                    <mat-icon class="layout__menu-icon"
                                        >how_to_reg</mat-icon
                                    >
                                    <span class="layout__nav-text">
                                        {{ 'general:hr' | i18next }}
                                    </span>
                                </div>

                                <!-- Consultations -->
                                <div
                                    *permission="'personal_consultations'"
                                    (click)="toggleDrawer('consultations')"
                                    class="layout__nav-link layout__nav-link--sub"
                                    [ngClass]="{
                                        'layout__nav-link--active':
                                            isActiveRoute(
                                                router_arrays.consultations
                                            ),
                                        'layout__nav-link--active-link':
                                            active_drawer === 'consultations' &&
                                            last_active_drawer === 'open',
                                    }"
                                >
                                    <mat-icon class="layout__menu-icon"
                                        >3p</mat-icon
                                    >
                                    <span class="layout__nav-text">
                                        {{
                                            'general:personal_consultations'
                                                | i18next
                                        }}
                                    </span>
                                </div>

                                <!-- Statistic -->
                                <div
                                    (click)="toggleDrawer('statistic')"
                                    class="layout__nav-link layout__nav-link--sub"
                                    [ngClass]="{
                                        'layout__nav-link--active':
                                            isActiveRoute(
                                                router_arrays.statistics
                                            ),
                                        'layout__nav-link--active-link':
                                            active_drawer === 'statistic' &&
                                            last_active_drawer === 'open',
                                    }"
                                >
                                    <mat-icon class="layout__menu-icon"
                                        >show_chart</mat-icon
                                    >
                                    <span class="layout__nav-text">
                                        {{ 'general:statistic' | i18next }}
                                    </span>
                                </div>

                                <!-- Settings -->
                                <div
                                    (click)="toggleDrawer('settings')"
                                    class="layout__nav-link layout__nav-link--sub"
                                    [ngClass]="{
                                        'layout__nav-link--active':
                                            isActiveRoute(
                                                router_arrays.settings
                                            ),
                                        'layout__nav-link--active-link':
                                            active_drawer === 'settings' &&
                                            last_active_drawer === 'open',
                                    }"
                                >
                                    <mat-icon class="layout__menu-icon"
                                        >tune</mat-icon
                                    >
                                    <span class="layout__nav-text">
                                        {{ 'general:settings' | i18next }}
                                    </span>
                                </div>

                                <!-- Help -->
                                <div
                                    *ngIf="permissions.help"
                                    (click)="toggleDrawer('help')"
                                    class="layout__nav-link layout__nav-link--sub"
                                    [ngClass]="{
                                        'layout__nav-link--active':
                                            isActiveRoute(router_arrays.help),
                                        'layout__nav-link--active-link':
                                            active_drawer === 'help' &&
                                            last_active_drawer === 'open',
                                    }"
                                >
                                    <mat-icon class="layout__menu-icon"
                                        >help</mat-icon
                                    >
                                    <span class="layout__nav-text">
                                        {{ 'page-tt-layout:help' | i18next }}
                                    </span>
                                </div>

                                <!-- Others -->
                                <div
                                    *ngIf="permissions.others"
                                    (click)="toggleDrawer('others')"
                                    class="layout__nav-link layout__nav-link--sub"
                                    [ngClass]="{
                                        'layout__nav-link--active':
                                            isActiveRoute(router_arrays.others),
                                        'layout__nav-link--active-link':
                                            active_drawer === 'others' &&
                                            last_active_drawer === 'open',
                                    }"
                                >
                                    <mat-icon class="layout__menu-icon"
                                        >category</mat-icon
                                    >
                                    <span class="layout__nav-text">
                                        {{ 'general:others' | i18next }}
                                    </span>
                                </div>
                            </li>

                            <li
                                #bottom_menu
                                class="layout__nav-item"
                            >
                                <!-- Units -->
                                <mat-select
                                    [(value)]="school._id"
                                    class="layout__school"
                                    *ngIf="user.schools?.length > 1"
                                >
                                    <mat-option
                                        *ngFor="let s of user.schools"
                                        [value]="s._id"
                                    >
                                        <a
                                            [routerLink]="[
                                                '/',
                                                'schedule',
                                                s._id,
                                            ]"
                                            class="layout__school-link"
                                        >
                                            {{ s.title }}
                                        </a>
                                    </mat-option>
                                </mat-select>

                                <!-- Bugs -->
                                <button
                                    *ngIf="errors.length"
                                    (click)="drawer.toggle()"
                                    class="layout__nav-link layout__nav-link--sub layout__nav-link--sub-bottom layout__nav-link--error-btn"
                                >
                                    <mat-icon
                                        class="layout__menu-icon"
                                        [attr.aria-label]="
                                            'page-tt-layout:view_bug_report_button'
                                                | i18next
                                        "
                                    >
                                        bug_report
                                    </mat-icon>
                                    <span class="layout__nav-text">
                                        {{ 'general:error' | i18next }} ({{
                                            errors.length
                                        }})
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </mat-drawer>

        <mat-drawer-content
            class="layout__drawer-content"
            [style.marginLeft.px]="opened_main_menu ? '200' : '46'"
        >
            <mat-drawer-container
                class="layout"
                [hasBackdrop]="true"
                autosize
                (backdropClick)="closeDrawer()"
            >
                <mat-drawer
                    #drawer
                    mode="over"
                    position="end"
                    (closedStart)="closeErrors()"
                    class="layout__drawer"
                >
                    <div class="layout__drawer-clear">
                        <button
                            class="layout__drawer-clear-button"
                            mat-button
                            (click)="clearErrors()"
                        >
                            {{ 'general:action_clear' | i18next }}
                        </button>
                    </div>
                    <app-alert
                        *ngFor="let error of errors"
                        [data]="error"
                    ></app-alert>
                </mat-drawer>
                <mat-drawer
                    #sub_drawer
                    class="layout__sidenav"
                    mode="over"
                >
                    <div
                        [ngSwitch]="active_drawer"
                        class="layout__sidenav-block"
                    >
                        <ng-container *ngSwitchCase="'education'">
                            <span class="layout__title">
                                {{ 'general:education' | i18next }}
                            </span>
                            <ul class="layout__nav">
                                <!-- Schedule -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="[
                                            '/',
                                            'schedule',
                                            school?._id,
                                        ]"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            calendar_month
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:schedule'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('schedule'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Broadcasts -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="[
                                            '/',
                                            'meetings-dashboard',
                                        ]"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            play_circle_filled
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:broadcasts'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('broadcasts'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Classes -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'classes'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'classes']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >meeting_room</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:classes'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('classes'),
                                        }"
                                    ></ng-container>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngSwitchCase="'groups'">
                            <span class="layout__title">
                                {{ 'general:groups' | i18next }}
                            </span>
                            <ul class="layout__nav">
                                <!-- Sprints -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'sprints'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'sprints']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >streetview</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:sprints'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('sprints'),
                                        }"
                                    ></ng-container>
                                </li>
                                <!-- Invite Student -->
                                <li
                                    *permission="'student_add'"
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'invite']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            local_activity
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:invite_student'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('invite'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Groups -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="
                                        'groups';
                                        schools: [school._id]
                                    "
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'groups']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >group</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{ 'general:groups' | i18next }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('groups'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Reserve in groups -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="
                                        'groups_students';
                                        schools: [school._id]
                                    "
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'reserves']"
                                        [queryParams]="{
                                            skip: 0,
                                            direction: 1,
                                            sort: 'reserve_terms_date',
                                        }"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            lock</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:reserve_in_groups'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu(
                                                    'reserve_in_groups'
                                                ),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Keycalls -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'keycalls'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'keycalls']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            phone</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:keycalls'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('keycalls'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Group chats LMS -->
                                <li
                                    *permission="'lms_chat'"
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'groups-chats']"
                                        [queryParams]="{
                                            skip: 0,
                                            direction: 1,
                                            sort: 'start_date',
                                        }"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            forum</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:group_chats_lms'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu(
                                                    'group_chats_lms'
                                                ),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Polls -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'polls'; schools: [school._id]"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'polls']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >poll</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:polls' | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('polls'),
                                        }"
                                    ></ng-container>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngSwitchCase="'courses'">
                            <span class="layout__title">
                                {{ 'page-tt-layout:courses' | i18next }}
                            </span>
                            <ul class="layout__nav">
                                <!-- Courses -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'courses'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'courses']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >category</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:courses'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('courses'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Programme -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'programme'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'programme']"
                                        [queryParams]="{
                                            direction: 1,
                                            sort: 'createdAt',
                                        }"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            chrome_reader_mode
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:programme'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('programme'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- конспекти / workbook з підменюшками -->

                                <!-- Recommendations -->
                                <li
                                    *permission="'recommendations'"
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'recommendations']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >ballot</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:recommendations'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu(
                                                    'recommendations'
                                                ),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Courses events -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'course_events'"
                                >
                                    <a
                                        [routerLink]="[
                                            '/',
                                            'courses',
                                            'events',
                                        ]"
                                        class="layout__nav-route"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >spellcheck</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:course_events'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('course_events'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Video courses -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'video-courses'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'videocourses']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >video_library</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:video_courses'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('video_courses'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Newsletters -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'mailings'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'mailings']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >email</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:newsletters'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('newsletters'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Code tasks -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'code_tasks_management'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'code-tasks']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            check_circle
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:code_tasks'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('code_tasks'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Quiz -->
                                <li
                                    *permission="'quiz'"
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'quiz']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >quiz</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:quiz_title'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('quiz'),
                                        }"
                                    ></ng-container>
                                </li>

                                <li
                                    *permission="'workbooks'"
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'workbooks']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >menu_book</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:workbooks'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('workbooks'),
                                        }"
                                    ></ng-container>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngSwitchCase="'people'">
                            <span class="layout__title">
                                {{ 'general:people' | i18next }}
                            </span>
                            <ul class="layout__nav">
                                <!-- Support -->
                                <li
                                    *permission="'support'"
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'support']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            contact_support
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{ 'general:support' | i18next }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('support'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Achievements -->
                                <li
                                    *permission="'achievements'"
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'achievements']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            workspace_premium
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'general:achievements' | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('achievements'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Freeze students -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        routerLink="/freeze-student"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            hourglass_full
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:freeze_students'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu(
                                                    'freeze_students'
                                                ),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Limited students -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        routerLink="/limited-student"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            not_interested
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:limited_students'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu(
                                                    'limited_students'
                                                ),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Users -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'users_list'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'users']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            people_outline
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{ 'general:users' | i18next }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('users'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Teachers -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="
                                        'coaches';
                                        schools: [school._id]
                                    "
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'coaches']"
                                        [queryParams]="{
                                            skip: 0,
                                            direction: 1,
                                        }"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >school</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:teachers'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('teachers'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Birthdays -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'birthdays'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'birthdays']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >cake</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:birthdays'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('birthdays'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Base of certificates -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'certificates_generate'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'certificates']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            featured_play_list
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:base_of_certificates'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu(
                                                'base_of_certificates'
                                            ),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Merging accounts -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'merge_users'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'merge']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            supervised_user_circle
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:merging_accounts'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu(
                                                    'merging_accounts'
                                                ),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Confirms -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'calendars_confirms'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'confirms']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            assignment_late
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:correction_requests'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu(
                                                'correction_requests'
                                            ),
                                        }"
                                    ></ng-container>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngSwitchCase="'money'">
                            <span class="layout__title">
                                {{ 'general:money' | i18next }}
                            </span>
                            <ul class="layout__nav">
                                <!-- Fops -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'fops_add'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'fops']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >payment</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:fops' | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('fops'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Budget -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *ngIf="permissions.budget"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'budget']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            import_contacts
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:budget'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('budget'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Promocodes -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'coupons'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'coupon']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >subtitles</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:promocode'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('promocode'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Cash -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="
                                        'cashbox_watch_school';
                                        schools: [school._id]
                                    "
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'cashbox']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >attach_money</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:cash' | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('cashbox'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Categories of expenses -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'cashbox_manage_category'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="[
                                            '/',
                                            'cashbox-categories',
                                        ]"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >assignment</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:categories_of_expenses'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu(
                                                'categories_of_expenses'
                                            ),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Payments -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="
                                        'fondy_orders_view';
                                        schools: [school._id]
                                    "
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'payments']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >how_to_vote</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:payments'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('payments'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Return applications -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'refunds'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="[
                                            '/',
                                            'refund-statements',
                                        ]"
                                        [queryParams]="{
                                            skip: 0,
                                            direction: 1,
                                            sort: 'createdAt',
                                        }"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >description</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:return_applications'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu(
                                                'return_applications'
                                            ),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Withdrawals -->
                                <li
                                    *permission="'referral_withdrawals'"
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'withdrawals']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            account_balance_wallet
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:withdrawals'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('withdrawals'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Extracts -->
                                <li
                                    *permission="'odb_fops'"
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'odb-fops']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            receipt</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:excerpts'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('excerpts'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Expected payments amount -->
                                <li
                                    *permission="'expected_payments_amount'"
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="[
                                            '/',
                                            'expected-payments-amount',
                                        ]"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            receipt</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:expected_payments_amount'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu(
                                                'expected_payments_amount'
                                            ),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Referrals -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'referrals']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            supervisor_account
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:referrals'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('referrals'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- VPO -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'vpo']"
                                        [queryParams]="{
                                            skip: 0,
                                            limit: 40,
                                            status: 'confirmed,filled',
                                        }"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            location_on</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{ 'page-tt-layout:vpo' | i18next }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('vpo'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!--  Debtors -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'debtors'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'debtors']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            money_off</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:debtors'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('debtors'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!--  User balances -->
                                <li
                                    *permission="'user_balances'"
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="[
                                            '/',
                                            'customer-balances',
                                        ]"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            account_balance_wallet
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:customer_balances'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu(
                                                    'customer_balances'
                                                ),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Purchases -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'courses_purchase_view'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'purchases']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >shopping_cart</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:purchases'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('purchases'),
                                        }"
                                    ></ng-container>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngSwitchCase="'hr'">
                            <span class="layout__title">
                                {{ 'general:hr' | i18next }}
                            </span>
                            <ul class="layout__nav">
                                <!-- Interviews -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'interviews'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'interviews']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >people</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:interviews'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('interviews'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Resume base -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="
                                        'student_cv';
                                        schools: [school._id]
                                    "
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'cvs']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            assignment_ind
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:resume_base'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('resume_base'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Skills title -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'management_skills'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'skills']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            fitness_center
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:skills_title'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('skills'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Companies -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'company_view'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'companies']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >style</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:company'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('companies'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Vacancies -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'vacancy'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'vacancies']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >work</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:vacancies'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('vacancies'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Employment -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'vacancy'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="[
                                            '/',
                                            'students-employment',
                                        ]"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >check_circle</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:employment'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu(
                                                'students_employment'
                                            ),
                                        }"
                                    ></ng-container>
                                </li>
                                <!-- Checklist -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'checklist']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >checklist</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:checklist'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('checklist'),
                                        }"
                                    ></ng-container>
                                </li>

                                <li
                                    *permission="'longlist'"
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        routerLink="/longlist"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            local_activity
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:longlist'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('long_list'),
                                        }"
                                    ></ng-container>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngSwitchCase="'consultations'">
                            <span class="layout__title">
                                {{ 'general:personal_consultations' | i18next }}
                            </span>
                            <ul class="layout__nav">
                                <!-- Consultations -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        routerLink="/consultations-list"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >3p</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:personal_consultations'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('consultations'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Cancellations -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        routerLink="/consultations/cancellations"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            free_cancellation
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:consultations_cancellations'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu(
                                                'consultations_cancellations'
                                            ),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Consultations coaches -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        routerLink="/consultations/consultants"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            assignment_ind
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:consultations_coaches'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu(
                                                'consultations_coaches'
                                            ),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Consultations topics -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        routerLink="/consultations/topics"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >label</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:consultations_topics'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu(
                                                'consultations_topics'
                                            ),
                                        }"
                                    ></ng-container>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngSwitchCase="'statistic'">
                            <span class="layout__title">
                                {{ 'general:statistic' | i18next }}
                            </span>
                            <ul class="layout__nav">
                                <!-- Units -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'schools']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >apartment</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:schools'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('schools'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Loading courses -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'statistics_courses_loaded'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="[
                                            '/',
                                            'statistics',
                                            'courses',
                                        ]"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >category</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:loading_courses'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu(
                                                    'loading_courses'
                                                ),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Start accuracy -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'statistics_courses_delay'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="[
                                            '/',
                                            'statistics',
                                            'delay',
                                        ]"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            slow_motion_video
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:start_accuracy'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('start_accuracy'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Support -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'statistics_support'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="[
                                            '/',
                                            'statistics',
                                            'support',
                                        ]"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >support_agent</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:statistics_support'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu(
                                                    'support_statistics'
                                                ),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- NPS -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'statistics_nps'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="[
                                            '/',
                                            'statistics',
                                            'nps',
                                        ]"
                                        [queryParams]="{
                                            role_group: 'student',
                                        }"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >how_to_vote</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{ 'page-tt-layout:nps' | i18next }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('nps'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- cNPS -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'statistics_nps'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="[
                                            '/',
                                            'statistics',
                                            'nps',
                                        ]"
                                        [queryParams]="{
                                            role_group: 'teacher',
                                        }"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >how_to_vote</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:cnps' | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('cnps'),
                                        }"
                                    ></ng-container>
                                </li>
                                <ng-container
                                    *permission="'statistics_employment'"
                                >
                                    <!-- School employment -->
                                    <li
                                        class="layout__nav-link layout__nav-link--pin"
                                        routerLinkActive="layout__nav-link--active-sub"
                                    >
                                        <a
                                            class="layout__nav-route"
                                            [routerLink]="[
                                                '/',
                                                'statistics',
                                                'employment',
                                                'schools',
                                            ]"
                                        >
                                            <mat-icon class="layout__menu-icon">
                                                location_city
                                            </mat-icon>
                                            <span
                                                class="layout__nav-text layout__nav-text--sub"
                                            >
                                                {{
                                                    'page-tt-layout:school_employment'
                                                        | i18next
                                                }}
                                            </span>
                                        </a>
                                        <ng-container
                                            [ngTemplateOutlet]="button"
                                            [ngTemplateOutletContext]="{
                                                $implicit:
                                                    getLayoutMenu(
                                                        'school_employment'
                                                    ),
                                            }"
                                        ></ng-container>
                                    </li>

                                    <!-- Employment courses -->
                                    <li
                                        class="layout__nav-link layout__nav-link--pin"
                                        routerLinkActive="layout__nav-link--active-sub"
                                    >
                                        <a
                                            class="layout__nav-route"
                                            [routerLink]="[
                                                '/',
                                                'statistics',
                                                'employment',
                                                'courses',
                                            ]"
                                        >
                                            <mat-icon class="layout__menu-icon"
                                                >school</mat-icon
                                            >
                                            <span
                                                class="layout__nav-text layout__nav-text--sub"
                                            >
                                                {{
                                                    'page-tt-layout:employment_courses'
                                                        | i18next
                                                }}
                                            </span>
                                        </a>
                                        <ng-container
                                            [ngTemplateOutlet]="button"
                                            [ngTemplateOutletContext]="{
                                                $implicit:
                                                    getLayoutMenu(
                                                        'employment_courses'
                                                    ),
                                            }"
                                        ></ng-container>
                                    </li>
                                </ng-container>

                                <!-- Average course duration -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="
                                        'statistics_group_average_time'
                                    "
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="[
                                            '/',
                                            'statistics',
                                            'average',
                                        ]"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            hourglass_empty
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:average_course_duration'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu(
                                                'average_course_duration'
                                            ),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Resume statistics -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'statistics_cvs'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="[
                                            '/',
                                            'statistics',
                                            'cvs',
                                        ]"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            contacts</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:resume'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('cvs_statistics'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Performance -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'statistics_coach_performance'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="[
                                            '/',
                                            'statistics',
                                            'coaches',
                                            'rating',
                                        ]"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            transfer_within_a_station
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:performance'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('performance'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Statistics app -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'statistics'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="[
                                            '/',
                                            'statistics',
                                            'app',
                                        ]"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >phone_android</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{ 'page-tt-layout:app' | i18next }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('statistics_app'),
                                        }"
                                    ></ng-container>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngSwitchCase="'settings'">
                            <span class="layout__title">
                                {{ 'general:settings' | i18next }}
                            </span>
                            <ul class="layout__nav">
                                <!-- App updates -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'app-history']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >history</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:app_updates'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('app_updates'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- LMS updates -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'lms_updates'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'updates']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            update</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:lms_updates'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('lms_updates'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Localization -->
                                <li
                                    *permission="'localizations_management'"
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'localizations']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            local_library
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:localization'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('localization'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Tasks -->
                                <li
                                    *permission="'tasks_settings'"
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'tasks']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            watch</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:tasks' | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('tasks_settings'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Mail settings title -->
                                <li
                                    *permission="'mailing_config'"
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'mail-config']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            mail_outline
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:mail_title'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('mail_settings'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Type polls -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="
                                        'polls_create';
                                        schools: [school._id]
                                    "
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'questions']"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >toc</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:type_polls'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('type_polls'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Store title -->
                                <li
                                    *permission="'store_settings'"
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'store']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            shopping_basket
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:store_title'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('store'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Parser links -->
                                <li
                                    *permission="'links_parser'"
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'parser']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            link
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:links_parser'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('links_parser'),
                                        }"
                                    ></ng-container>
                                </li>
                                <li
                                    *permission="'system_control'"
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'system-control']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            toggle_on
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:action_system'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('system_control'),
                                        }"
                                    ></ng-container>
                                </li>
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'material-ui']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            scatter_plot
                                        </mat-icon>
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            Material UI
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu('system_control'),
                                        }"
                                    ></ng-container>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngSwitchCase="'help'">
                            <span class="layout__title">
                                {{ 'page-tt-layout:help' | i18next }}
                            </span>
                            <ul class="layout__nav">
                                <!-- Help -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'help_page'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="['/', 'help']"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            live_help</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:help' | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('help'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Coach instruction -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'coach_instruction'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="[
                                            '/',
                                            'coach-instruction',
                                        ]"
                                    >
                                        <mat-icon class="layout__menu-icon">
                                            list_alt</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:coach_instruction'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit:
                                                getLayoutMenu(
                                                    'coach_instruction'
                                                ),
                                        }"
                                    ></ng-container>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngSwitchCase="'others'">
                            <span class="layout__title">
                                {{ 'general:others' | i18next }}
                            </span>
                            <ul class="layout__nav">
                                <!-- Video -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'video'; schools: [school._id]"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="[
                                            '/',
                                            'videos',
                                            school._id,
                                            'cameras',
                                        ]"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >videocam</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:video' | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu('video'),
                                        }"
                                    ></ng-container>
                                </li>

                                <!-- Password reset logs -->
                                <li
                                    class="layout__nav-link layout__nav-link--pin"
                                    routerLinkActive="layout__nav-link--active-sub"
                                    *permission="'password_reset_logs'"
                                >
                                    <a
                                        class="layout__nav-route"
                                        [routerLink]="[
                                            '/',
                                            'password-reset-logs',
                                        ]"
                                    >
                                        <mat-icon class="layout__menu-icon"
                                            >lock_reset</mat-icon
                                        >
                                        <span
                                            class="layout__nav-text layout__nav-text--sub"
                                        >
                                            {{
                                                'page-tt-layout:password_reset_logs'
                                                    | i18next
                                            }}
                                        </span>
                                    </a>
                                    <ng-container
                                        [ngTemplateOutlet]="button"
                                        [ngTemplateOutletContext]="{
                                            $implicit: getLayoutMenu(
                                                'password_reset_logs'
                                            ),
                                        }"
                                    ></ng-container>
                                </li>
                            </ul>
                        </ng-container>
                    </div>

                    <!-- pin button -->
                    <ng-template
                        #button
                        let-data
                    >
                        <button
                            *ngIf="!pinned_map[data.title]"
                            class="layout__nav-button"
                            (click)="pinItem(data)"
                        >
                            <mat-icon class="layout__nav-button-icon"
                                >push_pin</mat-icon
                            >
                        </button>
                    </ng-template>
                </mat-drawer>

                <mat-drawer-content>
                    <div
                        class="layout__content"
                        [ngClass]="{
                            'layout__content--open-sidebar':
                                opened_main_menu && !url_support,
                            'layout__content--mobile-sidebar':
                                opened_main_menu && url_support,
                        }"
                    >
                        <div class="layout__outlet">
                            <app-page-header></app-page-header>
                            <router-outlet></router-outlet>
                            <div
                                class="layout__loader"
                                [class.show]="load"
                            >
                                <div class="layout__loader-content">
                                    <mat-spinner></mat-spinner>
                                    <app-avatar
                                        *ngIf="birthdayUser as user"
                                        class="layout__loader-birthday"
                                        [images]="user.avatars"
                                        thumb="400"
                                    ></app-avatar>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-drawer-content>
            </mat-drawer-container>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
