import { Component, Input, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { getImageUrl } from '../../shared/common';
import { I18NextPipe } from 'angular-i18next';
import {
    Gallery,
    GalleryItem,
    GalleryRef,
    ImageItem,
    ImageSize,
} from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
    public gallery_ref: GalleryRef;
    public gallery_images: GalleryItem[] = [];
    public preview;
    public status_config = {};

    @Input('thumb') public thumb = '100';

    @Input('open') public open = false;

    public _status;

    @Input('images') set images(value) {
        if (!environment.production || (value && value.length === 0)) {
            const userpic = getImageUrl();

            this.gallery_images = [
                new ImageItem({
                    src: userpic,
                    thumb: userpic,
                }),
            ];

            this.preview = userpic;
        } else if (Array.isArray(value)) {
            this.gallery_images = value.map((image) => {
                return new ImageItem({
                    src: getImageUrl({ image, size: '400' }),
                    thumb: getImageUrl({ image, size: '100' }),
                });
            });

            this.preview = getImageUrl({ image: value[0], size: this.thumb });
        }
    }

    @Input('status') set status(value) {
        this._status = {
            size: value.size || '',
            name: value.name || '',
        };
        this.status_config = {};

        if (this._status.size === 'small') {
            this.status_config['status--small'] = true;
        }

        if (!this._status.name) return;

        if (this._status.name === 'tick') {
            this.status_config['status--good'] = true;
            this._status.tooltip = this.i18NextPipe.transform('general:works');
        }

        if (this._status.name === 'stopped') {
            this.status_config['status--danger'] = true;
            this._status.tooltip =
                this.i18NextPipe.transform('general:not_works');
        }

        if (this._status.name === 'paused') {
            this.status_config['status--warning'] = true;
            this._status.tooltip =
                this.i18NextPipe.transform('general:on_pause');
        }
    }

    get status(): {
        size: string;
        name: string;
    } {
        return this._status;
    }

    constructor(
        public gallery: Gallery,
        public lightbox: Lightbox,
        private i18NextPipe: I18NextPipe,
    ) {}

    public ngOnInit(): void {
        if (this.open) {
            this.gallery_ref = this.gallery.ref('lightbox');
            this.gallery_ref.setConfig({
                thumb: false,
                imageSize: ImageSize.Contain,
            });
        }
    }

    public openLightbox(): void {
        if (this.open) {
            this.gallery_ref.load(this.gallery_images);
            this.lightbox.open(0, 'lightbox', { panelClass: 'fullscreen' });
        }
    }
}
