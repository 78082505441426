import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { GroupsService } from '../groups/groups.service';

@Injectable()
export class GroupCheckGuardService {
    constructor(
        private router: Router,
        private groupService: GroupsService,
    ) {}

    public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        try {
            const group = await this.groupService
                .checkGroup(route.params.group_id)
                .toPromise();

            if (!group.success) {
                throw new Error('Group Not Found');
            }

            return group.success;
        } catch (error) {
            this.router.navigate(['/error/404']);
            return false;
        }
    }
}
