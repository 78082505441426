import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { AlertComponent } from './alert.component';
import { I18NextModule } from 'angular-i18next';

@NgModule({
    declarations: [AlertComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        I18NextModule,
    ],
    exports: [AlertComponent],
})
export class AlertModule {}
