import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
    public transform(
        value: Array<{ [key: string]: string }>,
        field?: string,
    ): string {
        if (Array.isArray(value)) {
            return value[0]
                ? value[0][field] || value[0]['full_number'] || ''
                : '';
        }

        if (typeof value === 'object') {
            return value[field] || value['full_number'] || '';
        }

        return value;
    }
}
