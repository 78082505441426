import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '@services/http/http.service';

@Injectable()
export class QuizService {
    constructor(private http: HttpService) {}

    public getQuizzes(query: {
        skip: number;
        limit: number;
        search?: string;
        attachments: boolean;
        updated_from?: string;
        updated_to?: string;
        sort?: string;
        direction?: 'asc' | 'desc';
    }): Observable<any> {
        let params = new HttpParams({
            fromObject: {
                skip: query.skip,
                limit: query.limit,
                not_connected: query.attachments.toString(),
            },
        });

        if (query.search) {
            params = params.set('search', query.search);
        }

        if (query.updated_from) {
            params = params.set('updated_from', query.updated_from);
        }

        if (query.updated_to) {
            params = params.set('updated_to', query.updated_to);
        }

        if (query.sort) {
            params = params.set('sort', query.sort);
        }

        if (query.direction) {
            const dir = query.direction === 'asc' ? '1' : '-1';

            params = params.set('direction', dir);
        }

        return this.http.get(`/api/quiz`, { params });
    }

    public getAllResults(query: {
        skip: number;
        limit: number;
        status: string;
        search?: string;
        sort?: string;
        direction?: 'asc' | 'desc';
        updated_from?: string;
        updated_to?: string;
    }): Observable<any> {
        let params = new HttpParams({
            fromObject: {
                skip: query.skip,
                limit: query.limit,
                'statuses[]': query.status ? [query.status] : [],
            },
        });

        if (query.search) {
            params = params.set('search', query.search);
        }

        if (query.updated_from) {
            params = params.set('updated_from', query.updated_from);
        }

        if (query.updated_to) {
            params = params.set('updated_to', query.updated_to);
        }

        if (query.sort) {
            params = params.set('sort', query.sort);
        }

        if (query.direction) {
            const dir = query.direction === 'asc' ? '1' : '-1';

            params = params.set('direction', dir);
        }

        return this.http.get('/api/quiz/answers', { params });
    }

    public getResultsByQuiz(
        quiz_id: string,
        query: {
            skip: number;
            limit: number;
            status: string;
            search?: string;
            sort?: string;
            direction?: 'asc' | 'desc';
            updated_from?: string;
            updated_to?: string;
        },
    ): Observable<any> {
        let params = new HttpParams({
            fromObject: {
                skip: query.skip,
                limit: query.limit,
                'statuses[]': query.status ? [query.status] : [],
            },
        });

        if (query.search) {
            params = params.set('search', query.search);
        }

        if (query.updated_from) {
            params = params.set('updated_from', query.updated_from);
        }

        if (query.updated_to) {
            params = params.set('updated_to', query.updated_to);
        }

        if (query.sort) {
            params = params.set('sort', query.sort);
        }

        if (query.direction) {
            const dir = query.direction === 'asc' ? '1' : '-1';

            params = params.set('direction', dir);
        }

        return this.http.get(`/api/quiz/${quiz_id}/answers`, { params });
    }

    public getQuiz(quiz_id: string): Observable<any> {
        return this.http.get(`/api/quiz/${quiz_id}`);
    }

    public deleteQuizResult(result_id: string): Observable<any> {
        return this.http.delete(`/api/quiz/answers/${result_id}`);
    }

    public getQuizAttachments(quiz_id: string): Observable<any> {
        return this.http.get(`/api/quiz/${quiz_id}/connections`);
    }

    public createQuiz(value: any): Observable<any> {
        return this.http.post('/api/quiz', value);
    }

    public updateQuiz(quiz_id: string, value: any): Observable<any> {
        return this.http.put(`/api/quiz/${quiz_id}`, value);
    }

    public attachQuiz(quiz_id: string, value: any): Observable<any> {
        return this.http.post(`/api/quiz/${quiz_id}/connect`, value);
    }

    public dettachQuiz(connection_id: string): Observable<any> {
        return this.http.post(`/api/quiz/${connection_id}/disconnect`, {});
    }

    public searchQuiz(search: string): Observable<any> {
        return this.http.get(`/api/quiz/search/${encodeURIComponent(search)}`);
    }

    public getAttachedQuiz(
        type: string,
        connected_to: string,
    ): Observable<any> {
        const params = new HttpParams({ fromObject: { connected_to } });

        return this.http.get(`/api/quiz/connected/${type}`, { params });
    }
}
