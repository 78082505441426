<div
    class="profile-photo"
    (click)="openLightbox()"
>
    <img
        *ngIf="preview"
        class="profile-photo__image"
        [src]="preview"
        [alt]="'general:profile_photo' | i18next"
    />
</div>
