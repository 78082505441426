import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutDialogComponent } from './layout-dialog.component';
import { I18NextModule } from 'angular-i18next';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDivider } from '@angular/material/divider';

@NgModule({
    imports: [
        CommonModule,
        I18NextModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        DragDropModule,
        MatDivider,
    ],
    declarations: [LayoutDialogComponent],
})
export class LayoutDialogModule {}
