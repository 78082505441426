import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { GlobalService } from '../global/global.service';

export interface Response<T = any> {
    success: boolean;
    response?: T;
}

@Injectable()
export class HttpService {
    public headers = new HttpHeaders();
    public options = { headers: this.headers, withCredentials: true };

    constructor(
        private http: HttpClient,
        private globalService: GlobalService,
    ) {}

    public get(url: string, options?): Observable<any> {
        return this.request('get', [url, { ...this.options, ...options }]);
    }

    public post(url: string, data: Object, options?): Observable<any> {
        return this.request('post', [
            url,
            data,
            { ...this.options, ...options },
        ]);
    }

    public put(url: string, data: Object, options?): Observable<any> {
        return this.request('put', [
            url,
            data,
            { ...this.options, ...options },
        ]);
    }

    public delete(url: string, options?): Observable<any> {
        return this.request('delete', [url, { ...this.options, ...options }]);
    }

    public request(type: string, params: any[]): Observable<any> {
        params[0] = `${environment.apiUrl}${params[0]}`;

        this.globalService.addRequest(params[0], type);

        return this.http[type](...params).pipe(
            map((d) => {
                this.globalService.removeRequest(params[0], type);
                return d;
            }),
            catchError((err) => {
                this.globalService.removeRequest(params[0], type);
                return throwError(err);
            }),
        );
    }

    public encodeParams(params: { [key: string]: string }): string {
        return Object.keys(params).reduce((acc, key) => {
            const param = `${key}=${encodeURIComponent(params[key])}`;

            acc += !acc ? `?${param}` : `&${param}`;

            return acc;
        }, '');
    }
}
