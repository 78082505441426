import { Pipe, PipeTransform } from '@angular/core';

import { Constants } from './../../constants/constants';
import { I18NextPipe } from 'angular-i18next';

@Pipe({ name: 'days' })
export class DaysPipe implements PipeTransform {
    constructor(private i18NextPipe: I18NextPipe) {}

    public transform(days: number): string {
        days = Math.round(days);
        const month = Math.floor(days / Constants.DAYS_OF_MONTH);
        const day = days % Constants.DAYS_OF_MONTH;
        return `${
            month
                ? `${month} ${this.i18NextPipe.transform('general:months_shortened')}`
                : ''
        } ${
            day
                ? `${day} ${this.i18NextPipe.transform('general:days_shortened')}`
                : ''
        }`.trim();
    }
}
