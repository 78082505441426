import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { PermissionsService } from '../permissions/permissions.service';

@Injectable()
export class AchievementsGuardService {
    constructor(
        private router: Router,
        private permissionsService: PermissionsService,
    ) {}

    public canActivate(): boolean | UrlTree {
        return (
            this.permissionsService.checkPermission({
                name: 'achievements',
            }) || this.router.parseUrl('/error/403')
        );
    }
}
