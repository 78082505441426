import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { HttpService } from '../http/http.service';

@Injectable()
export class ImagesService {
    constructor(private http: HttpService) {}

    public upload(value: FormData, query?: string): Observable<any> {
        let params = new HttpParams();

        if (query && query === 'achievement_image') {
            params = params.append('achievement_image', query);
        } else if (query) {
            params = params.append('logo', query);
        }

        return this.http.post(`/api/images`, value, { params });
    }
}
