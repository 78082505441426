import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { PermissionsService } from '@services/permissions/permissions.service';

@Injectable({ providedIn: 'root' })
export class LonglistGuard {
    constructor(
        private permissionsService: PermissionsService,
        private router: Router,
    ) {}

    public canActivate(): boolean | UrlTree {
        return (
            this.permissionsService.checkPermission({ name: 'longlist' }) ||
            this.router.parseUrl('/error/403')
        );
    }
}
