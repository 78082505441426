import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appDisableNumberInputScroll]',
})
export class DisableNumberInputScrollDirective {
    @HostListener('wheel', ['$event'])
    public onWheel(event: WheelEvent): void {
        event.preventDefault();
    }

    @HostListener('keydown', ['$event'])
    public onKeydown(event: KeyboardEvent): void {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault();
        }
    }
}
