import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/ru-UA';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuillModule } from 'ngx-quill';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './components/layout/layout.module';
import { ServerErrorInterceptor } from './interceptors/server-error.interceptor';
import { GuardsModule } from './services/auth/guards.module';
import { ClickService } from './services/click/click.service';
import { CoursesService } from './services/courses/courses.service';
import { ErrorService } from './services/error/error.service';
import { GlobalService } from './services/global/global.service';
import { GroupsService } from './services/groups/groups.service';
import { GroupsChatsService } from './services/groups-chats/groups-chats.service';
import { HttpService } from './services/http/http.service';
import { ImagesService } from './services/images/images.service';
import { NewCoachService } from './services/new-coach/new-coach.service';
import { PollService } from './services/poll/poll.service';
import { SchoolsService } from './services/schools/schools.service';
import { StatisticsService } from './services/statistics/statistics.service';
import { TelegramService } from './services/telegram/telegram.service';
import { UserService } from './services/user/user.service';
import { TokenService } from './services/token/token.service';
import { WorkerService } from './services/worker/worker.service';
import { WorkhistoryService } from './services/workhistorys/workhistorys';
import { ApplicationDirectivesModule } from './shared/application-directives.module';
import { ImageChecker } from './shared/image-checker';
import { I18NextModule } from 'angular-i18next';
import { I18N_PROVIDERS } from '@shared/translation/translation-i18n';
import { InstructionService } from '@services/instruction/instruction.service';
import { QuizService } from '@services/quiz/quiz.service';
import { HeaderEventsService } from '@services/header-events/header-events.service';
import { WorkbookService } from '@services/workbook/workbook.service';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/button-toggle';

registerLocaleData(localeFr, 'ru-UA');

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutModule,
        ApplicationDirectivesModule,
        GuardsModule,
        MatSnackBarModule,
        QuillModule.forRoot(),
        I18NextModule.forRoot(),
    ],
    providers: [
        GlobalService,
        HttpService,
        GroupsService,
        SchoolsService,
        ImagesService,
        ImageChecker,
        PollService,
        UserService,
        StatisticsService,
        CoursesService,
        ClickService,
        WorkhistoryService,
        NewCoachService,
        TelegramService,
        TokenService,
        GroupsChatsService,
        InstructionService,
        WorkerService,
        QuizService,
        HeaderEventsService,
        WorkbookService,
        { provide: LOCALE_ID, useValue: 'ru-UA' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerErrorInterceptor,
            multi: true,
        },
        ErrorService,
        I18N_PROVIDERS,
        {
            provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
            useValue: {
                hideSingleSelectionIndicator: true,
            },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
