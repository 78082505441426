import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PermissionsService } from './../services/permissions/permissions.service';
import { PermissionDirective } from './directives/permission/permission.directive';
import { DisableNumberInputScrollDirective } from '@shared/directives/disable-number-input-scroll/disable-number-input-scroll.directive';

@NgModule({
    imports: [CommonModule],
    exports: [PermissionDirective, DisableNumberInputScrollDirective],
    declarations: [PermissionDirective, DisableNumberInputScrollDirective],
    providers: [PermissionsService],
})
export class ApplicationDirectivesModule {}
