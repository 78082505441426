import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './layout.component';
import { I18NEXT_NAMESPACE_RESOLVER } from 'angular-i18next';

const routes: Routes = [
    {
        path: '',
        data: {
            i18nextNamespaces: ['page-tt-layout'],
        },
        resolve: {
            i18next: I18NEXT_NAMESPACE_RESOLVER,
        },
        component: LayoutComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class LayoutRoutingModule {}
