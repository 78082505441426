import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import {
    CameraRoom,
    SocketState,
    SocketType,
} from '../../workers/socket.constants';

@Injectable()
export class WorkerService {
    public socket_subs = {
        [SocketType.CHAT]: new Subject<any>(),
        [SocketType.CHATS]: new Subject<any>(),
        [SocketType.INVITES]: new Subject<any>(),
        [SocketType.CAMERA]: new Subject<any>(),
        [SocketType.VIDEOS]: new Subject<any>(),
        [SocketType.PURCHASES]: new Subject<any>(),
    };

    public socket_state$ = new Subject<string>();

    private _worker: Worker;

    public createWorker(): void {
        if (typeof Worker !== 'undefined') {
            this._worker = new Worker(
                new URL('../../workers/socket.worker', import.meta.url),
            );

            this._worker.onmessage = ({ data }): void => {
                if (
                    data.state === SocketState.RECONNECT ||
                    data.state === SocketState.DISCONNECT
                ) {
                    this.socket_state$.next(data.state);
                }

                if (data.type) {
                    this.socket_subs[data.type].next({
                        payload: data.data,
                        room: data.room,
                    });
                }
            };
        } else {
            console.warn('Web Workers are not supported in this environment.');
        }
    }

    public postMessage(data: {
        type: SocketType;
        state: SocketState;
        payload?: string | any;
        emit_event?: CameraRoom;
    }): void {
        if (this._worker) {
            this._worker.postMessage(data);
        }
    }

    public closeSocket(): void {
        this._worker.postMessage({ close: true });
    }

    public destroyWorker(): void {
        if (this._worker) {
            this._worker.terminate();
        }
    }
}
