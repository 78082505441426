import { Component, Input, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import { getImageUrl } from '../../shared/common';
import {
    Gallery,
    GalleryItem,
    GalleryRef,
    ImageItem,
    ImageSize,
} from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';

@Component({
    selector: 'app-profile-photo',
    templateUrl: './profile-photo.component.html',
    styleUrls: ['./profile-photo.component.scss'],
})
export class ProfilePhotoComponent implements OnInit {
    public gallery_ref: GalleryRef;
    public gallery_images: GalleryItem[] = [];
    public preview;

    @Input('thumb') public thumb = '100';

    @Input('images') set images(value) {
        if (!environment.production || (value && value.length === 0)) {
            const userpic = getImageUrl();

            this.gallery_images = [
                new ImageItem({
                    src: userpic,
                    thumb: userpic,
                }),
            ];

            this.preview = userpic;
        } else if (Array.isArray(value)) {
            this.gallery_images = value.map((image) => {
                return new ImageItem({
                    src: getImageUrl({ image, size: '400' }),
                    thumb: getImageUrl({ image, size: '100' }),
                });
            });

            this.preview = getImageUrl({ image: value[0], size: this.thumb });
        }
    }

    @Input('open') public open = true;

    constructor(
        public gallery: Gallery,
        public lightbox: Lightbox,
    ) {}

    public ngOnInit(): void {
        this.gallery_ref = this.gallery.ref('lightbox');
        this.gallery_ref.setConfig({
            thumb: false,
            imageSize: ImageSize.Contain,
        });
        this.gallery_ref.load(this.gallery_images);
    }

    public openLightbox(): void {
        if (!this.open) return;

        this.lightbox.open(0, 'lightbox', { panelClass: 'fullscreen' });
    }
}
