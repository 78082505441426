import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ErrorService } from '../services/error/error.service';

import { Constants } from './../constants/constants';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private errorService: ErrorService,
    ) {}

    public intercept(
        request: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (!error) return throwError(error);

                if (isDevMode) {
                    console.error('Request ERROR', error);
                }

                if (error.status === Constants.NOT_AUTHORIZED) {
                    this.router.navigate(['/login']);
                } else {
                    this.errorService.showError(error);
                }

                return throwError(error);
            }),
        );
    }
}
