import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AvatarComponent } from './avatar.component';
import { I18NextModule } from 'angular-i18next';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';

@NgModule({
    declarations: [AvatarComponent],
    imports: [
        CommonModule,
        GalleryModule,
        LightboxModule,
        MatTooltipModule,
        I18NextModule,
    ],
    exports: [AvatarComponent],
})
export class AvatarModule {}
