import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs/internal/Observable';

import { compare } from '../../shared/common';
import { HttpService } from '../http/http.service';

@Injectable()
export class ErrorService {
    private _stored_errors = JSON.parse(
        sessionStorage.getItem('invoked_errors') || '[]',
    );

    get stored_errors(): any[] {
        return this._stored_errors;
    }

    constructor(
        public snackBar: MatSnackBar,
        private http: HttpService,
    ) {}

    public showError(error: any): void {
        const message = this.getErrorText(error);

        this.snackBar.open(message, 'x', {
            panelClass: ['alert', 'alert--error'],
            duration: 10000,
        });

        this._stored_errors.push({
            date: moment().format('DD.MM.YYYY[,] HH:mm'),
            type: 'error',
            error,
        });

        this._stored_errors.sort((a, b) =>
            compare(Date.parse(a.date), Date.parse(b.date), true),
        );

        this.save();
    }

    public sendErrorReport(error): Observable<any> {
        return this.http.post(`/api/support/send-error-to-bugs-chat`, error);
    }

    public showedErrors(): void {
        this._stored_errors = this._stored_errors.map((error) => {
            error.showed = true;
            return error;
        });

        this.save();
    }

    public save(): void {
        sessionStorage.setItem(
            'invoked_errors',
            JSON.stringify(this._stored_errors),
        );
    }

    public getErrorText(error: any): string {
        const errors = [];

        if (error.message) {
            errors.push(error.message);
        }

        if (
            error.error &&
            error.error.errors &&
            error.error.errors.length > 0
        ) {
            errors.push(...error.error.errors);
        }

        return errors.join('\n');
    }

    public clearErrors(): void {
        this._stored_errors = [];
        this.save();
    }
}
