import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { NewCoachService } from '../new-coach/new-coach.service';
import { UserService } from '../user/user.service';

@Injectable()
export class NewCoachCheckAndSchoolAccessGuardService {
    constructor(
        private router: Router,
        private newCoachService: NewCoachService,
        private userService: UserService,
    ) {}

    public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        try {
            const { success, response } = await this.newCoachService
                .checkNewCoach(route.params.new_coach_id)
                .toPromise();

            if (!success) {
                throw new Error('New Coach Not Found');
            }

            this.userService.current_profile = response;

            return success;
        } catch (error) {
            this.router.navigate(['/error/404']);
            return false;
        }
    }
}
