import {
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { GlobalService } from '@services/global/global.service';
import { HeaderEventsService } from '@services/header-events/header-events.service';

import {
    HeaderSearch,
    HeaderTab,
    HeaderAction,
    HeaderMenu,
    HeaderConstants,
    HeaderConfig,
    HeaderSelect,
    HeaderBreadcrumb,
    AutocompleteOptions,
} from './page-header.constants';

@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit, OnDestroy {
    public search: HeaderSearch | null;
    public tabs: Array<HeaderTab> | null;
    public actions: Array<HeaderAction> | null;
    public select: HeaderSelect | null;
    public breadcrumbs: Array<HeaderBreadcrumb> = [];
    public autocomplete_options: AutocompleteOptions = [];

    public disabled_elements = new Map<string, boolean>();

    private _sub$ = new Subscription();
    private _save_hot_key: boolean;

    public get page_title(): string {
        return this.globalService.page_title;
    }

    @ViewChild('searchInput', { read: ElementRef })
    public search_input: ElementRef<HTMLInputElement>;

    constructor(
        private dialog: MatDialog,
        private globalService: GlobalService,
        private headerEvents: HeaderEventsService,
    ) {}

    public ngOnInit(): void {
        this._sub$.add(
            this.headerEvents
                .getHeaderElements()
                .subscribe((value: HeaderConfig) => {
                    this.search = value?.search;
                    this.tabs = value?.tabs;
                    this.actions = value?.actions;
                    this.select = value?.select;
                    this.breadcrumbs = value?.breadcrumbs || [];

                    if (this.actions?.length) {
                        this._save_hot_key = this.actions.some(
                            (item) =>
                                item.name === 'save' && item.hotkey_enable,
                        );
                    }
                }),
        );

        this._sub$.add(
            this.headerEvents.getDisableElement().subscribe((item) => {
                if (item) {
                    this.disabled_elements.set(item.name, item.value);
                } else {
                    this.disabled_elements.clear();
                }
            }),
        );

        this._sub$.add(
            this.headerEvents.getAutocompleteOptions().subscribe((options) => {
                this.autocomplete_options = options;
            }),
        );
    }

    public ngOnDestroy(): void {
        this._sub$?.unsubscribe();
    }

    @HostListener('document:keydown', ['$event'])
    public onFocusSearch(event: KeyboardEvent): void {
        if (this.search && this.search_input && this.search.hotkey_enable) {
            if (event.key === 'f' && (event.metaKey || event.ctrlKey)) {
                event.preventDefault();
                this.search_input.nativeElement.focus();
            }
        }
    }

    @HostListener('document:keydown', ['$event'])
    public onSaveData(event: KeyboardEvent): void {
        if (
            this._save_hot_key &&
            event.key === 's' &&
            (event.metaKey || event.ctrlKey)
        ) {
            event.preventDefault();
            this.click('save');
        }
    }

    public openMenu(element: MatButton, menu: HeaderMenu): void {
        const {
            bottom,
            right,
        }: {
            bottom: number;
            right: number;
        } = element._elementRef.nativeElement.getBoundingClientRect();

        const menu_width = this.getMenuWidth(menu.config.width);

        this.dialog
            .open(menu.template, {
                ...menu.config,
                position: {
                    left: `${right - menu_width}px`,
                    top: `${bottom + HeaderConstants.TOP_OFFSET}px`,
                },
            })
            .afterClosed()
            .subscribe(() => {
                this.headerEvents.menu_closed$.next();
            });
    }

    public autocompleteSearch(search: string): void {
        this.headerEvents.autocomplete_search$.next(search);
    }

    public autocompleteClosed(value: string): void {
        if (this.search.autocomplete?.enabled && value) {
            this.search.control.patchValue(value);
        }
    }

    public click(name: string): void {
        this.headerEvents.action_clicked$.next(name);
    }

    private getMenuWidth(width: string): number {
        const matches = width.match(/[\d.]+/);

        if (matches) {
            return parseFloat(matches[0]);
        }

        return 0;
    }
}
