import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { GlobalService } from '../global/global.service';
import { PermissionsService } from '../permissions/permissions.service';

@Injectable()
export class CoachesGuardService {
    get school(): any {
        return this.globalService.school;
    }

    constructor(
        private router: Router,
        private permissionsService: PermissionsService,
        private globalService: GlobalService,
    ) {}

    public canActivate(): boolean {
        if (
            !this.permissionsService.checkPermission({
                name: 'coaches',
                schools: [this.school._id],
            })
        ) {
            this.router.navigate(['/error/403']);
            return false;
        }

        return true;
    }
}
