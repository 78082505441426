import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpService } from '../http/http.service';

const BASE_URL = '/api/interviews';

@Injectable({
    providedIn: 'root',
})
export class InterviewsService {
    private _prev_route: string;
    private _filters: any;

    public set prev_route(value: string) {
        if (value) this._prev_route = value;
    }
    public get prev_route(): string {
        return this._prev_route;
    }

    public get filters(): any {
        return this._filters;
    }
    public set filters(value: any) {
        this._filters = value;
    }

    constructor(private http: HttpService) {}

    public getInterviews(
        params: {
            status?: string;
            start_date_interval?: { start_date: string; end_date: string };
        } = {},
    ): Observable<any> {
        let queryParams = new HttpParams();

        if (params.status) {
            queryParams = queryParams.append('statuses', params.status);
        }

        if (params.start_date_interval) {
            queryParams = queryParams.append(
                'start_date_from',
                params.start_date_interval.start_date,
            );
            queryParams = queryParams.append(
                'start_date_to',
                params.start_date_interval.end_date,
            );
        }

        return this.http.get(BASE_URL, { params: queryParams });
    }

    public getInterviewsResults(
        params: { interview_id?: string; interviewee_id?: string } = {},
    ): Observable<any> {
        let queryParams = new HttpParams();

        if (params.interview_id) {
            queryParams = queryParams.append(
                'interview_id',
                params.interview_id,
            );
        }

        if (params.interviewee_id) {
            queryParams = queryParams.append(
                'interviewee_id',
                params.interviewee_id,
            );
        }

        return this.http.get(`${BASE_URL}/results`, { params: queryParams });
    }

    public postInterviews(data: any): Observable<any> {
        return this.http.post(BASE_URL, data);
    }

    public editInterviews(id: string, data: any): Observable<any> {
        return this.http.put(`${BASE_URL}/${id}`, data);
    }

    public deleteInterviews(id: string): Observable<any> {
        return this.http.delete(`${BASE_URL}/${id}`);
    }

    public getRegisteredStudents(value: {
        skip: number;
        limit: number;
        sort?: string;
        direction?: number;
        search?: string;
        course?: string;
        statuses?: string[];
        registration_date_range?: string[];
        end_date_range?: string[];
    }): Observable<any> {
        let params = new HttpParams({
            fromObject: {
                skip: value.skip,
                limit: value.limit,
                'statuses[]': value.statuses || [],
                'registration_date_range[]':
                    value.registration_date_range || [],
                'end_date_range[]': value.end_date_range || [],
            },
        });

        if (value.direction)
            params = params.append('direction', value.direction);
        if (value.sort) params = params.append('sort', value.sort);

        if (value.search) params = params.append('search', value.search);
        if (value.course) params = params.append('course', value.course);

        return this.http.get(`/api/interview-registration`, { params });
    }

    public getInterviewStudents(interview_id: string): Observable<any> {
        return this.http.get(`${BASE_URL}/${interview_id}`);
    }

    public removeInterview(registration_id: string): Observable<any> {
        return this.http.delete(
            `/api/interview-registration/${registration_id}`,
        );
    }
}
