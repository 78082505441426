import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoursePipe } from './course.pipe';
import { DayNamePipe } from './day-name.pipe';
import { DaysPipe } from './days.pipe';
import { DeclensionPipe } from './declension.pipe';
import { FilterPipe } from './filter.pipe';
import { GetPropertyPipe } from './get.pipe';
import { GroupNamePipe } from './group-name.pipe';
import { MoneyPipe } from './money.pipe';
import { NamePipe } from './name.pipe';
import { ParseLinksPipe } from './parse-links.pipe';
import { PhonePipe } from './phone.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SecondsPipe } from './seconds.pipe';
import { TitleHyphenPipe } from './title-hyphen-case.pipe';
import { UsersPipe } from './users.pipe';
import { CommandPipe, WorkStatusPipe } from './work-status.pipe';

@NgModule({
    imports: [CommonModule],
    exports: [
        DeclensionPipe,
        FilterPipe,
        MoneyPipe,
        WorkStatusPipe,
        SecondsPipe,
        CommandPipe,
        DaysPipe,
        UsersPipe,
        DayNamePipe,
        TitleHyphenPipe,
        PhonePipe,
        GetPropertyPipe,
        GroupNamePipe,
        NamePipe,
        CoursePipe,
        SafeHtmlPipe,
        ParseLinksPipe,
    ],
    declarations: [
        DeclensionPipe,
        FilterPipe,
        MoneyPipe,
        WorkStatusPipe,
        SecondsPipe,
        CommandPipe,
        DaysPipe,
        UsersPipe,
        DayNamePipe,
        TitleHyphenPipe,
        PhonePipe,
        GetPropertyPipe,
        GroupNamePipe,
        NamePipe,
        CoursePipe,
        SafeHtmlPipe,
        ParseLinksPipe,
    ],
    providers: [NamePipe, CoursePipe, GroupNamePipe],
})
export class ApplicationPipesModule {}
