<div
    class="avatar"
    (click)="openLightbox()"
>
    <img
        *ngIf="preview"
        class="avatar__image"
        [ngClass]="{
            'avatar__image--open': open,
        }"
        [src]="preview"
        [alt]="'general:profile_photo' | i18next"
    />

    <div
        *ngIf="status"
        class="status"
        [ngClass]="status_config"
        [matTooltip]="status.tooltip"
        matTooltipPosition="above"
    ></div>
</div>
