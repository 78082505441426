import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { AccountStudentGroupsStatistics } from '../../components/account/account-groups/account-groups.constants';
import { GroupStatistics } from '../../components/groups/group-statistics/group-statistics.constants';
import { ParticipantRoleGroup } from '../../components/groups/groups.constants';
import {
    CoursesDelayStatisticsResponseData,
    SchoolCourseDelayStatisticsResponseDataItem,
} from '../../components/statistics/statistics-delay/statistics-delay.constants';
import { SupportStatisticsResponseData } from '../../components/statistics/statistics-support/statistics-support.constants';
import { GlobalService } from '../global/global.service';
import { HttpService, Response } from '../http/http.service';

@Injectable()
export class StatisticsService {
    constructor(
        private http: HttpService,
        public globalService: GlobalService,
    ) {}

    public getCoursesLoaded(
        startDate: string,
        endDate: string,
    ): Observable<any> {
        const query_params = this.http.encodeParams({
            startDate,
            endDate,
        });

        return this.http.get(`/api/statistics/courses/loaded${query_params}`);
    }

    public getGroupsLoadedOfCourses(
        startDate: string,
        endDate: string,
        course: string,
        slug: string,
    ): Observable<any> {
        const query_params = this.http.encodeParams({
            startDate,
            endDate,
        });

        return this.http.get(
            `/api/statistics/courses/${course}/${slug}/loaded${query_params}`,
        );
    }

    public getPolls(
        startDate: string,
        endDate: string,
        role_group: ParticipantRoleGroup,
    ): Observable<any> {
        const query_params = this.http.encodeParams({
            startDate,
            endDate,
            role_group,
        });

        return this.http.get(`/api/statistics/nps${query_params}`);
    }

    public getSummaryEmploymentStatistics(startDate, endDate): Observable<any> {
        const query_params = this.http.encodeParams({
            startDate,
            endDate,
        });

        return this.http.get(
            `/api/statistics/employment/schools${query_params}`,
        );
    }

    public getEmploymentStatisticsForHRs(startDate, endDate): Observable<any> {
        const query_params = this.http.encodeParams({
            startDate,
            endDate,
        });

        return this.http.get(`/api/statistics/employment/hrs${query_params}`);
    }

    public getSchoolEmploymentStatistics(startDate, endDate): Observable<any> {
        const query_params = this.http.encodeParams({
            startDate,
            endDate,
        });

        return this.http.get(
            `/api/statistics/employment/courses${query_params}`,
        );
    }

    public getEmploymentsForUsers(query: {
        startDate: string;
        endDate: string;
        school?: string;
        status?: string;
        manager?: string;
        manager_status?: string;
        course?: string;
        sort?: string;
        direction?: string;
    }): Observable<Response> {
        let params = new HttpParams({
            fromObject: {
                startDate: query.startDate,
                endDate: query.endDate,
            },
        });

        if (query.school) {
            params = params.set('school', query.school);
        }

        if (query.status) {
            params = params.set('status', query.status);
        }

        if (query.manager) {
            params = params.set('manager', query.manager);
        }

        if (query.manager_status) {
            params = params.set('manager_status', query.manager_status);
        }

        if (query.sort) {
            params = params.set('sort', query.sort);
        }

        if (query.direction) {
            const dir = query.direction === 'asc' ? '1' : '-1';

            params = params.set('direction', dir);
        }

        return this.http.get('/api/statistics/employment/users', { params });
    }

    public getGroupsAverage(
        startDate: string,
        endDate: string,
    ): Observable<any> {
        const query_params = this.http.encodeParams({
            startDate,
            endDate,
        });

        return this.http.get(`/api/statistics/groups/average${query_params}`);
    }

    public getCvsStatisticsAll(startDate, endDate): Observable<any> {
        const query_params = this.http.encodeParams({
            startDate,
            endDate,
        });

        return this.http.get(`/api/statistics/cvs${query_params}`);
    }

    public getCoachesRatingStatistics(
        start_date,
        end_date,
        for_role = 'coach',
        schools?: any[],
        courses?: any[],
    ): Observable<any> {
        let params = new HttpParams()
            .set('start_date', start_date)
            .set('end_date', end_date);

        params = params.append('for_role', for_role);

        if (schools && schools.length) {
            params = this.globalService.createQueryArrayString(
                params,
                schools,
                'schools',
            );
        }

        if (courses && courses.length) {
            params = this.globalService.createQueryArrayString(
                params,
                courses,
                'courses',
            );
        }

        return this.http.get('/api/statistics/coaches/rating', { params });
    }

    public getCoursesDelay(
        startDate: string,
        endDate: string,
    ): Observable<Response<CoursesDelayStatisticsResponseData>> {
        const query_params = this.http.encodeParams({
            startDate,
            endDate,
        });

        return this.http.get(`/api/statistics/courses/delay${query_params}`);
    }

    public getSchoolCourseDelayStatistics(
        startDate: string,
        endDate: string,
        course_id: string,
        school_slug: string,
    ): Observable<Response<SchoolCourseDelayStatisticsResponseDataItem[]>> {
        const query_params = this.http.encodeParams({
            startDate,
            endDate,
        });

        return this.http.get(
            `/api/statistics/courses/${course_id}/${school_slug}/delay${query_params}`,
        );
    }

    public getSupportStatistics(
        startDate: string,
        endDate: string,
    ): Observable<Response<SupportStatisticsResponseData>> {
        const query_params = this.http.encodeParams({
            startDate,
            endDate,
        });

        return this.http.get(`/api/statistics/support${query_params}`);
    }

    public getStudentStatistics(
        user_id: string,
    ): Observable<Response<AccountStudentGroupsStatistics[]>> {
        return this.http.get(`/api/statistics/students/${user_id}/groups`);
    }

    public getGroupStatistics(
        group_id: string,
    ): Observable<Response<GroupStatistics[]>> {
        return this.http.get(`/api/statistics/groups/${group_id}/students`);
    }

    public getAppStatistics(
        startDate: string,
        endDate: string,
    ): Observable<any> {
        const query: any = {};

        if (startDate) {
            query.startDate = startDate;
        }

        if (endDate) {
            query.endDate = endDate;
        }

        const params = new HttpParams({ fromObject: query });

        return this.http.get('/api/statistics/mobile-app', { params });
    }
}
