import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { PermissionsService } from '../permissions/permissions.service';
import { UserService } from '../user/user.service';

@Injectable()
export class UserEditGuardService {
    constructor(
        private router: Router,
        private permissionsService: PermissionsService,
        private userService: UserService,
    ) {}

    public canActivate(): boolean {
        if (
            !this.permissionsService.checkPermission({
                name: 'users_edit',
                user: this.userService.current_profile._id,
                schools: this.userService.current_profile.schools,
            })
        ) {
            this.router.navigate(['/error/403']);
            return false;
        }

        return true;
    }
}
