import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { GlobalService } from '../../services/global/global.service';
import { HttpService } from '../http/http.service';

interface Permission {
    name: string;
    user?: string;
    schools?: string[];
}

@Injectable()
export class PermissionsService {
    get permissions(): any {
        return this.globalService.permissions;
    }

    get user(): any {
        return this.globalService.user;
    }

    constructor(
        private http: HttpService,
        private globalService: GlobalService,
    ) {}

    public addPermission(value): Observable<any> {
        return this.http.post(`/api/permissions`, value);
    }

    public getUserPermissions(user_id: string): Observable<any> {
        return this.http.get(`/api/permissions/user/${user_id}`);
    }

    public checkPermission({ name, user, schools }: Permission): boolean {
        if (user && this.user._id === user) {
            return true;
        }

        if (!name) return false;

        const permission = this.globalService.permissions[name];

        if (!permission || !permission.enabled) return false;

        if (permission.general) return true;

        if (
            schools &&
            schools.some((school) => permission.schools.includes(school))
        ) {
            return true;
        }

        return false;
    }

    public checkOneOfAll(permissions: Permission[] = []): boolean {
        return permissions.reduce((acc, permission) => {
            if (this.checkPermission(permission)) return true;
            return acc;
        }, false);
    }
}
