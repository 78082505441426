import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { HttpService } from '../../services/http/http.service';

@Injectable()
export class NewCoachService {
    constructor(private http: HttpService) {}

    public invitingNewCoach(value): Observable<any> {
        return this.http.post(`/api/invite-users/coach`, value);
    }

    public getNewCoach(new_coach_id: string): Observable<any> {
        return this.http.get(`/api/invite-users/coach/${new_coach_id}`);
    }

    public getSchoolNewCoaches(): Observable<any> {
        return this.http.get('/api/invite-users/all/coach');
    }

    public updateNewCoach(coach_id: string, value): Observable<any> {
        return this.http.put(`/api/invite-users/coach/${coach_id}`, value);
    }

    public removeNewCoach(coach_id: string): Observable<any> {
        return this.http.delete(`/api/invite-users/coach/${coach_id}`);
    }

    public checkNewCoach(coach_id: string): Observable<any> {
        return this.http.get(`/api/invite-users/coach/${coach_id}/check`);
    }
}
