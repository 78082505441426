<div
    #alert_body
    class="alert alert--{{ data.type }}"
    [class.alert--showed]="data.showed"
>
    <mat-icon class="alert__icon">error</mat-icon>
    <div class="alert__content">
        <h2 class="alert__title">{{ data.date }}</h2>
        <p class="alert__message">
            {{ errorService.getErrorText(data.error) }}
        </p>
    </div>
    <div class="alert__actions">
        <button
            class="alert__action-button"
            mat-icon-button
            [matMenuTriggerFor]="actions_list"
        >
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actions_list="matMenu">
            <button
                class="alert__button"
                mat-menu-item
                (click)="copyAlertBody()"
            >
                <mat-icon>file_copy</mat-icon>
                <span> {{ 'general:copy' | i18next }}</span>
            </button>
            <button
                class="alert__button"
                mat-menu-item
                (click)="sendNotification()"
            >
                <mat-icon>notification_importantl</mat-icon>
                <span>{{ 'general:submit_report' | i18next }}</span>
            </button>
        </mat-menu>
    </div>
</div>
