import { Injectable } from '@angular/core';

import { GlobalService } from '../global/global.service';

@Injectable()
export class LoginGuardService {
    constructor(private globalService: GlobalService) {}

    public async canActivate(): Promise<boolean> {
        this.globalService.reset();
        return true;
    }
}
