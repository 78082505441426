import { Injectable } from '@angular/core';
import { UrlTree, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WorkbookService } from '@services/workbook/workbook.service';

@Injectable()
export class CheckWorkbookGuard {
    public get workbook(): any {
        return this.workbookService.active_workbook;
    }

    constructor(
        private router: Router,
        private workbookService: WorkbookService,
    ) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
    ): Observable<UrlTree | boolean> | boolean | UrlTree {
        const workbook_id: string = route.paramMap.get('workbook_id');

        if (this.workbook) {
            return true;
        }

        if (!workbook_id) {
            return this.router.parseUrl('/404');
        }

        return this.workbookService.getWorkbook(workbook_id).pipe(
            map(({ success, response }) => {
                if (success && response) {
                    this.workbookService.active_workbook = response;

                    return true;
                } else {
                    return this.router.parseUrl('/404');
                }
            }),
        );
    }
}
