import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { HttpService } from '../http/http.service';

@Injectable()
export class InstructionService {
    constructor(private http: HttpService) {}

    public searchInstruction({
        query,
        search_by_course,
        groups_ids,
    }: {
        query: string;
        search_by_course: boolean;
        groups_ids?: string[];
    }): Observable<any> {
        let params = new HttpParams();

        if (groups_ids) {
            params = new HttpParams({
                fromObject: {
                    'groups_ids[]': groups_ids || [],
                },
            });
        } else {
            params = search_by_course
                ? params.append('course', query || '')
                : params.append('title', query || '');
        }

        return this.http.get(`/api/instructions/search`, {
            params,
        });
    }
}
